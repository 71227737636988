import React, { useEffect } from 'react';
import VanillaTilt from 'vanilla-tilt';
import { useNavigate   } from 'react-router-dom';
import {validateToken} from './auth/authentication';

import './css/other.css'; // Import your local stylesheet
import backgroundImage from './images/back.jpg'; // Replace with the actual path to your image

const Dashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    validateToken(navigate, token);
    // Initialize VanillaTilt for all cards
    const cards = document.querySelectorAll(".card-dash");
    cards.forEach(card => {
      const tiltInstance = VanillaTilt.init(card, {
        glare: true,
        reverse: true,
        "max-glare": 0.15
      });

      return () => {
        tiltInstance.destroy(); // or tiltInstance.reset();
        card.removeEventListener('click', () => {});
      };
    });
  }, [navigate]); // Empty dependency array ensures this effect runs only once on component mount


  const handleClick = (value) => {
    window.location.href = value
  };

  return (
    <div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', background: `url(${backgroundImage})`, backgroundSize: 'cover', overflow: 'hidden' }}>
      <div className="card-dash rgb" id="card1" onClick={() => handleClick("/whatsapp-manager")}>
        <div className="card-image"></div>
        <div className="card-text">
          <span className="date">4 days ago</span>
          <h2>Whatsapp</h2>
          <p>Lorem ipsum dolor sit demoise amet consectetur, Ducimusele, repudiandae temporibus omnis illum maxime quod deserunt eligendi dolor</p>
        </div>
      </div>
      <div className="card-dash rgb" id="card2">
        <div className="card-image card2"></div>
        <div className="card-text card2">
          <span className="date">1 week ago</span>
          <h2>SMS</h2>
          <p>Adipisicing elit. Ducimus, repudiandae corrupti tialeno des ameto temporibus omnis provident illum maxime quod. Lorem ipsum dolor</p>
        </div>
      </div>
      <div className="card-dash rgb" id="card3">
        <div className="card-image card3"></div>
        <div className="card-text card3">
          <span className="date">3 week ago</span>
          <h2>Email</h2>
          <p>Repudiandae repudiandae de corrupti amet temporibus omnis si provident illum maxime. Ducimus, lorem ipsum dolor adipisicing elit</p>
        </div>
      </div>
      <div className="card-dash rgb" id="card4" onClick={() => handleClick("/phone-manager")}>
        <div className="card-image card4"></div>
        <div className="card-text card4">
          <span className="date">outbound calls</span>
          <h2>Call</h2>
          <p><b>Enjoy crystal-clear voice quality and reliable connections as you effortlessly communicate with anyone, anywhere. Our global calling feature empowers users to stay connected internationally, fostering collaboration and communication without borders</b></p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
