import axios from "axios";
import { API_BASE_URL } from "../component/constants";

export const signUpNew = async (phone_number_id, waba_id, token) => {
    try {
        const requestData = {
            phone_number_id: phone_number_id,
            waba_id: waba_id
        };

        await axios.post(`${API_BASE_URL}/api/v1/whatsapp/signup`, requestData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

    } catch (error) {
        console.error('Error sending POST request:', error);
    }
};


export const GetPhoneNumbers = async (token) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/v1/whatsapp/phoneNumbers`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data.body;
    } catch (error) {
        console.error('Error sending GET request:', error);
    }
};


export const GetUploadFileId = async (token, file, type) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', type);

        const response = await axios.post(`${API_BASE_URL}/api/v1/whatsapp/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data.body;
    } catch (error) {
        console.error('Error sending POST request:', error);
    }
};


export const CreateTemplate = async (token, businessId, template) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/v1/whatsapp/template?businessId=${businessId}`, template, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }   
};



export const GetTemplates = async (token, businessId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/v1/whatsapp/template?businessId=${businessId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data.body;
    } catch (error) {
        return error.response.data;
    }   
};
