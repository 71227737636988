import React, { useEffect, useState } from 'react';
import '../Dashboard_Pages/assets/css/tailwind/tailwind.min.css';
import '../Dashboard_Pages/assets/css/theme.css';
import '../Dashboard_Pages/assets/css/loopple/loopple.css';
import { SideNavPhone, TopNav } from '../component/SideNav';
import { useNavigate } from 'react-router-dom';
import { CallRecordingTable} from '../component/Phone_Comp/phoneList';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { validateTokenNew, validateTokenRecording } from '../auth/authentication';
import { AleartMessage } from '../component/Aleart';
import { HandleDownload } from '../api/phone';
import InfinityLoader from '../component/COMP/loadingPage';

const CallRecording = () => {
    const [details, setDetails] = useState([]);
    const [enabled, setEnabled] = useState(false);
    const [currentbalance, setCurrentBalance] = useState();
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [filterData, setFilterData] = useState();
    const [access, setAccess] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const navigate = useNavigate();

    const token = localStorage.getItem('token');

    useEffect(() => {
        const validateTokenAndFetch = async () => {
            const response = await validateTokenRecording(navigate, token, "PHONERECORDING");
            try {
                setDetails(response.company);
                handleBalance(response.company.credits);
                setAccess(response.access);
                setDataList(response.callRecordingDates);
                setEnabled(true);
                setFilterData(response.callRecordingDates);
            } catch (err) {
                //window.location.href = '/dashboard'
            }
        };

        validateTokenAndFetch();
    }, [navigate, token]);

    const handleBalance = (balance) => {
        const formattedCredits = parseFloat(balance).toFixed(4);
        setCurrentBalance(formattedCredits);
        localStorage.setItem("balance", formattedCredits);
    }


    const handleSearchChange = (e) => {
        const newSearchQuery = e.target.value;
        setSearchQuery(newSearchQuery); 

        if (dataList.length > 0) {
            if (!newSearchQuery) {
                setFilterData(dataList);
            } else {
                let filteredData = dataList.filter(item =>
                    item.toLowerCase().includes(newSearchQuery.toLowerCase())
                );
                setFilterData(filteredData);
            }
        }
    }

    const downloadRecodings = async (date) => {
        buttonDisabled && AleartMessage({icon: "warning", title: "Oops...", text: "Downloading In-Progress Please Wait..."})
        setButtonDisabled(true);
        await HandleDownload(date, token);
        setButtonDisabled(false);
    }

    return (
        <div>
            {enabled ? (
                <div>
                    <SideNavPhone item={5} access={access} />
                    <div className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200" id="panel">
                        <TopNav companyName={details.name} balance={currentbalance} />
                        <h6 className='ml-10'></h6>
                        <div className="flex flex-wrap -mx-3 mb-5 removable">
                            <div className="w-full max-w-full px-3 mb-6 lg:w-full sm:flex-none xl:mb-0 drop-zone">
                                <div className="w-full px-6 py-6 mx-auto loopple-min-height-78vh text-slate-500">
                                    <div className="shadow-xl dop-shadow-lg  flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border mb-4">
                                        <div className={`${loading ? "blur-sm" : ""} p-6 pb-0 mb-0 bg-white rounded-t-2xl flex justify-between`}>
                                            <h6>
                                                <div className={"relative flex flex-wrap items-stretch w-80 transition-all rounded-lg ease-soft"}>
                                                    <span className="text-sm ease-soft leading-5 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none  bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                                                        <i className="z-0 fas fa-search" aria-hidden="true"></i>
                                                    </span>
                                                    <input type="text"
                                                        className="pl-9 text-sm focus:shadow-soft-primary-outline ease-soft w-30 leading-5 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none focus:transition-shadow"
                                                        placeholder="Type here..."
                                                        value={searchQuery}
                                                        disabled={loading}
                                                        onChange={handleSearchChange}
                                                    />
                                                </div>
                                            </h6>
                                        </div>
                                        <div className="flex-auto px-0 pt-0 pb-2">
                                            <div className="p-0 overflow-x-auto">
                                                <CallRecordingTable dataList={filterData} loading={loading} currentPage={currentPage} setCurrentPage={setCurrentPage} downloadRecodings={downloadRecodings} buttonDisabled={buttonDisabled} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : <div><InfinityLoader /></div>}
        </div>
    );
};

export default CallRecording;
