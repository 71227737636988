import React, { useState } from "react";
import 'choices.js/public/assets/styles/choices.css';
import { Pagination } from "./Pagination";

const TemplateTable = ({ dataList, itemsPerPage = 11}) => {
    const [currentPage, setCurrentPage] = useState(1);

    if (!Array.isArray(dataList)) {
        console.error('dataList must be an array.');
        return null;
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dataList.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(dataList.length / itemsPerPage);
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div>
            <div>
                <table className="items-center justify-center w-full mb-0 align-top border-gray-200 text-slate-500">
                    <thead className="align-bottom">
                        <tr>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Project</th>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Language</th>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Status</th>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Category</th>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((item, index) => (
                            <tr key={index}>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex px-3">
                                        <div className="my-auto">
                                            <h6 className="mb-0 leading-normal text-sm">{item.name}</h6>
                                        </div>
                                    </div>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <p className="mb-0 mx-4 font-semibold leading-normal text-sm">{item.language}</p>
                                </td>
                                <td className="p-2 leading-normal bg-transparent border-b text-sm whitespace-nowrap shadow-transparent">
                                    {(item.status === "PENDING REVIEW") && (
                                        <span className="bg-gradient-to-tl from-red-600 to-yellow-400 px-2 text-xxs rounded py-1 inline-block whitespace-nowrap text-center align-baseline font-bold uppercase leading-none text-white">PENDING REVIEW</span>
                                    )}
                                    {(item.status === "REJECTED") && (
                                        <span className="bg-gradient-to-r from-indigo-500 via-red-500 to-pink-500 px-2 text-xxs rounded py-1 inline-block whitespace-nowrap text-center align-baseline font-bold uppercase leading-none text-white">REJECTED</span>
                                    )}
                                    {(item.status === "APPROVED") && (
                                        <span className="mx-1 bg-gradient-to-tl from-green-600 to-lime-400 px-2 text-xxs rounded py-1 inline-block whitespace-nowrap text-center align-baseline font-bold uppercase leading-none text-white">APPROVED</span>
                                    )}
                                    {(item.status === "DISABLED") && (
                                        <span className="bg-gradient-to-tl bg-gray-300 text-gray-600 px-2 text-xxs rounded py-1 inline-block whitespace-nowrap text-center align-baseline font-bold uppercase leading-none text-white">DISABLED</span>
                                    )}
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <p className="mb-0 font-semibold leading-normal text-sm"> {item.category}</p>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <button value={item.id} type="button" className="inline-block px-6 py-3 mr-3 font-bold text-center align-middle transition-all rounded-lg cursor-pointer bg-fuchsia-500/0 leading-pro text-xs ease-soft-in tracking-tight-soft bg-150 bg-x-25 hover:bg-fuchsia-500/25 hover:scale-102 active:bg-fuchsia/45 text-fuchsia-500">Edit</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="py-2 flex justify-end">
                    <nav className="block">
                        <ul className="flex pl-0 rounded list-none flex-wrap">
                            <li>
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-pink-500 bg-white text-pink-500"
                                >
                                    <i className="fas fa-chevron-left -ml-px" aria-hidden="true"></i>
                                </button>
                            </li>
                            {Pagination(totalPages, currentPage, handlePageChange)}
                            <li>
                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                    className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-pink-500 bg-white text-pink-500"
                                >
                                    <i className="fas fa-chevron-right -mr-px" aria-hidden="true"></i>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export { TemplateTable };