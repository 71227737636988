import React, { useEffect, useRef, useState } from 'react';
import '../Dashboard_Pages/assets/css/tailwind/tailwind.min.css';
import '../Dashboard_Pages/assets/css/theme.css';
import '../Dashboard_Pages/assets/css/loopple/loopple.css';
import { SideNavPhone, TopNav } from '../component/SideNav';
import "../css/other.css"
import { useNavigate } from 'react-router-dom';
import { addNewUser, validateTokenUser } from '../auth/authentication';

import '../css/animation.css';
import { TableUser } from '../component/Table';
import { AleartMessage } from '../component/Aleart';
import InfinityLoader from '../component/COMP/loadingPage';

const AddUser = () => {
    const [numbers, setNumbers] = useState([]);
    const [enabled, setEnabled] = useState(false);
    const [currentbalance, setCurrentBalance] = useState();
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [access, setAccess] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [model, setModel] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isCheckedStatus, setIsCheckedStatus] = useState(true);
    const [isCheckedRecord, setIsCheckedRecord] = useState(false);
    const [isSubmited, setIsSubmited] = useState(false);
    const [isEdited, setIsEdited] = useState(0);

    const columList = ["User", "Email Address", "Status", "Call Record", "Employed", "Action"];
    const dropdownRef = useRef(null);
    const [userDetails, setUserDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
    });

    const options = [
        "Access call logs",
        "Handle transactions",
        "Add new users"
    ];

    const accessMapping = {
        "Access call logs": "CALL_LOGS",
        "Handle transactions": "PHONEPAY",
        "Add new users": "PHONEADMIN"
    };

    const navigate = useNavigate();

    const token = localStorage.getItem('token');

    useEffect(() => {
        const validateTokenAndFetch = async () => {
            const response = await validateTokenUser(navigate, token, "PHONEADMIN");
            try {
                setNumbers(response.company);
                setUsers(response.userList);
                setFilterData(response.userList);
                console.log(response.userList);
                setAccess(response.access)
                handleBalance(response.company.credits);
                setEnabled(true);
            } catch (err) {
                window.location.href = '/dashboard'
            }
        };
        validateTokenAndFetch();
    }, [navigate, token]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleBalance = (balance) => {
        const formattedCredits = parseFloat(balance).toFixed(4);
        setCurrentBalance(formattedCredits);
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    const handleSearchChange = (e) => {
        const newSearchQuery = e.target.value;
        setSearchQuery(newSearchQuery);

        if (users.length > 0) {
            if (newSearchQuery === undefined || newSearchQuery.length === 0) {
                setFilterData(users);
            } else {
                let filteredData = users.filter((item) => {
                    return Object.values(item).some((value) => {
                        if (typeof value === 'string' && value.toLowerCase().includes(newSearchQuery.toLowerCase())) {
                            return true;
                        }
                        return false;
                    });
                });
                setFilterData(filteredData);
            }
        }
    }

    const handleModel = () => {
        clearAll();
        setModel(!model);
    }

    // const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSelect = (option) => {
        if (selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.filter((selected) => selected !== option));
        } else {
            setSelectedOptions([...selectedOptions, option]);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetails(prevDetails => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (userDetails.firstName === '' || userDetails.lastName === '' || userDetails.email === '') {
            AleartMessage({ icon: 'warning', title: 'Fill all the details', text: '' });
            return;
        }
        const accessList = selectedOptions.map(item => accessMapping[item]);
        accessList.push("PHONE");

        const request = {
            "firstName": userDetails.firstName,
            "lastName": userDetails.lastName,
            "email": userDetails.email,
            "status": isCheckedStatus,
            "callRecord": isCheckedRecord,
            "access": accessList
        }
        setIsSubmited(true);
        const response = await addNewUser(token, request, isEdited);
        setIsSubmited(false);
        if (response === 201) {
            clearAll();
            AleartMessage({ icon: 'success', title: isEdited === 0 ? 'User added successfully' : 'User Details successfully Updated', text: isEdited === 0 && 'Crendentials sent to the user' });
        } else {
            AleartMessage({ icon: 'error', title: 'User already exists', text: 'The email you provided already being used in the system' });
        }
        setIsEdited(0);
        window.location.href = "/phone-manager/add-user"
    }

    const clearAll = () => {
        setUserDetails({ firstName: '', lastName: '', email: '' });
        setSelectedOptions([]);
        setIsCheckedStatus(true);
        setIsCheckedRecord(false);
        setModel(false);
        setIsEdited(0);
    }

    const handleCheckboxChangeStatus = () => {
        setIsCheckedStatus(!isCheckedStatus);
    };

    const handleCheckboxChangeRecord = () => {
        setIsCheckedRecord(!isCheckedRecord);
    };

    const handleEdit = (item) => {
        const obj = {
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
        }
        setUserDetails(obj);
        const updatedAccess = item.access.filter(accessItem => accessItem !== "PHONE");
        const invertedAccessMapping = Object.keys(accessMapping).reduce((acc, key) => {
            const value = accessMapping[key];
            acc[value] = key;
            return acc;
        }, {});
        const originalAccessList = updatedAccess.map(code => invertedAccessMapping[code]);
        setSelectedOptions(originalAccessList)
        setIsCheckedStatus(item.status);
        setIsCheckedRecord(item.callRecord)
        setIsEdited(item.id)
        setModel(true);
    }

    return (
        <div>
            {enabled ? (
                <div>
                    <SideNavPhone item={4} access={access} />
                    <div className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200" id="panel">
                        <TopNav companyName={numbers.name} balance={currentbalance} />
                        <div className="flex flex-wrap -mx-3 mb-5 removable">
                            <div className="w-full max-w-full px-3 mb-6 lg:w-full sm:flex-none xl:mb-0 drop-zone">
                                <div className={`relative flex flex-col ${model && 'blur-sm'} w-full min-w-0 mb-0 break-words bg-white shadow-xl rounded-2xl overflow-hidden`}>
                                    <div className="p-6 mb-4 pb-0 mb-0 bg-white">
                                        <div className="flex justify-between items-center w-full">
                                            <h6 className="text-xl font-semibold text-gray-800">List of users</h6>
                                            <div className=''>
                                                <button
                                                    type="button"
                                                    onClick={handleModel}
                                                    disabled={model}
                                                    className="px-6 py-3 font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-gradient-to-tl from-blue-700 to-green-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md hover:scale-102 active:opacity-85 hover:shadow-soft-xs">
                                                    <i className="fa fa-user-plus mr-2" aria-hidden="true"></i>Add New User
                                                </button>
                                            </div>
                                        </div>

                                        <div className={"relative flex flex-wrap items-stretch w-80 transition-all rounded-lg ease-soft"}>
                                            <span className="text-sm ease-soft leading-5 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none  bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                                                <i className="z-0 fas fa-search" aria-hidden="true"></i>
                                            </span>
                                            <input type="text"
                                                className="pl-9 text-sm focus:shadow-soft-primary-outline ease-soft w-30 leading-5 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none focus:transition-shadow"
                                                placeholder="Type here..."
                                                value={searchQuery}
                                                disabled={model}
                                                onChange={handleSearchChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full max-w-full px-3 mb-0 lg:w-full sm:flex-none xl:mb-0 drop-zone">
                                        <div className="relative flex justify-center flex-col h-full min-w-0 p-4 break-words bg-white shadow-xl rounded-2xl bg-clip-border">
                                            <TableUser dataList={filterData} columnList={columList} currentPage={currentPage} setCurrentPage={setCurrentPage} handleEdit={handleEdit} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {model && (
                                <div className="relative max-w-4xl w-full -top-52  mx-auto p-5 ">
                                    {isSubmited ? <div><InfinityLoader /></div> :
                                        (<form className="space-y-6 bg-white shadow-lg shadow-purple-500/50 h-[500px] rounded-2xl overflow-hidden">
                                            <div className="bg-gradient-to-r from-green-200 via-red-200 to-blue-100 p-3 text-white">
                                                <h2 className="text-lg font-semibold">{isEdited === 0 ? 'Create New User' : 'Update User Details'}</h2>
                                            </div>
                                            <div className="px-8 pb-2">
                                                <div className="flex mb-2 -mx-2">
                                                    <div className="px-2 w-1/2">
                                                        <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
                                                        <input type="text" name="firstName" id="firstName" required
                                                            onChange={handleChange}
                                                            value={userDetails.firstName}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            placeholder="John" />
                                                    </div>
                                                    <div className="px-2 w-1/2">
                                                        <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
                                                        <input type="text" name="lastName" id="lastName" required
                                                            onChange={handleChange}
                                                            value={userDetails.lastName}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            placeholder="Doe" />
                                                    </div>
                                                </div>
                                                <div className="w-full relative z-50 mb-2" ref={dropdownRef}>
                                                    <label htmlFor="access" className="block text-sm font-medium text-gray-700">User Access</label>
                                                    <div onClick={() => setIsOpen(!isOpen)} className="cursor-pointer flex flex-wrap items-center gap-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded-md p-2 bg-white shadow-sm">
                                                        {selectedOptions.map((option, index) => (
                                                            <span key={index} className="flex items-center gap-2 bg-indigo-500 text-white text-xs font-medium px-2.5 py-0.5 rounded-full">
                                                                {option}
                                                                <button type="button" onClick={(e) => { e.stopPropagation(); handleSelect(option); }} className="text-white hover:text-indigo-200">
                                                                    &times;
                                                                </button>
                                                            </span>
                                                        ))}
                                                        <span className="text-gray-400">{selectedOptions.length === 0 && "Select options"}</span>
                                                    </div>
                                                    {isOpen && (
                                                        <div className="absolute overflow-visible left-1/2 transform -translate-x-1/2 w-2/5 bg-white mt-1 rounded-lg shadow-lg max-h-60 overflow-auto z-50">
                                                            {options.map((option, index) => (
                                                                <div key={index}
                                                                    onClick={() => handleSelect(option)}
                                                                    className={`px-4 py-2 cursor-pointer rounded-lg flex justify-between items-center ${selectedOptions.includes(option) ? 'bg-indigo-500 text-white' : 'bg-white text-gray-900'}`}>
                                                                    <span>{option}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mb-2">
                                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                                                    <input type="email" name="email" id="email" required
                                                        onChange={handleChange}
                                                        value={userDetails.email}
                                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                        placeholder="john.doe@example.com" />
                                                </div>

                                                <div className="flex items-center justify-center space-x-4">
                                                    <div className="min-h-6 mb-0.5 flex items-center">
                                                        <input
                                                            id="status"
                                                            type="checkbox"
                                                            checked={isCheckedStatus}
                                                            onChange={handleCheckboxChangeStatus}
                                                            className={`rounded-[50px] duration-300 ease-in-out after:rounded-[50%] after:shadow-xl after:duration-300 h-[20px] relative float-left w-10 cursor-pointer appearance-none border border-solid border-grey-200 align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px ${isCheckedStatus ? 'after:bg-white' : 'after:bg-black'} checked:bg-gradient-to-r from-pink-400 to-pink-500 checked:border-[#3a416ff2] checked:after:translate-x-[21px]`}

                                                        />
                                                        <label htmlFor="status" className="inline-block pl-3 mb-0 ml-0 font-normal cursor-pointer select-none text-sm text-slate-700">
                                                            User Status is {isCheckedStatus ? "enabled" : "disabled"}
                                                        </label>
                                                    </div>
                                                    <div className="min-h-6 mb-0.5 flex items-center">
                                                        <input
                                                            id="record"
                                                            type="checkbox"
                                                            checked={isCheckedRecord}
                                                            onChange={handleCheckboxChangeRecord}
                                                            className={`rounded-[50px] duration-300 ease-in-out after:rounded-[50%] after:shadow-xl after:duration-300 h-[20px] relative float-left w-10 cursor-pointer appearance-none border border-solid border-grey-200 align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px ${isCheckedRecord ? 'after:bg-white' : 'after:bg-black'} checked:bg-gradient-to-r from-pink-400 to-pink-500 checked:border-[#3a416ff2] checked:after:translate-x-[21px]`}
                                                        />
                                                        <label htmlFor="record" className="inline-block pl-3 mb-0 ml-0 font-normal cursor-pointer select-none text-sm text-slate-700">
                                                            Call recording is {isCheckedRecord ? "enabled" : "disabled"}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="flex mb-2 mt-4 justify-between">
                                                    <button
                                                        type="button"
                                                        onClick={handleModel}
                                                        className="inline-block px-6 py-3 font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-gradient-to-tl from-gray-700 to-gray-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs">
                                                        Close
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        onClick={handleSubmit}
                                                        className="inline-block px-6 py-3 font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-gradient-to-tl from-green-700 to-lime-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs">
                                                        {isEdited === 0 ? 'Create' : 'Update'}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>)
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : <div><InfinityLoader /></div>}
        </div>
    );
};

export default AddUser;
