import React, { useState } from 'react';
import logo from './images/logo.png';
import wallpaper from './images/wallpaper.jpg';
import banner from './images/img.png';
import './css/other.css';
import { changePassword, checkOTP, forgotPassword, loginEvent } from './auth/authentication';
import { AleartMessage } from './component/Aleart';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, showLoading] = useState(false);
    const [error, setError] = useState('');
    const [enable, setEnable] = useState(1);
    const [otp, setOtp] = useState(1);
    const [rePassword, setRePassword] = useState('');
    const [rePasswordNew, setRePasswordNew] = useState('');

    if(enable === 1) {
        localStorage.clear();
    }

    const handleEmailChange = (e) => {
        if (error !== '') {
            setError('');
        }
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        if (error !== '') {
            setError('');
        }
        setPassword(e.target.value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        showLoading(true);
        const toekn = await loginEvent(email, password, showLoading);
        if (toekn === null) {
            showLoading(false);
            setError("invalid username or password");
        }
    };

    const handleFormEnable = (value) => {
        setEnable(value)
    }

    const sendOTP = async (e) => {
        e.preventDefault();
        localStorage.setItem('email', email)
        showLoading(true);
        const response = await forgotPassword(email);
        showLoading(false);
        if (response){
            setEnable(3);
        }
        else{
            setEnable(2);
            AleartMessage({ icon: 'error', title: 'Not Found', text: 'Usrname does not exists.' })
        }
    }

    const HandlecheckOTP = async (e) => {
        e.preventDefault();
        showLoading(true);
        const response = await checkOTP(otp);
        localStorage.setItem('lkt', otp)
        showLoading(false);
        if (response)
            setEnable(4);
        else
            AleartMessage({ icon: 'error', title: 'Invalid OTP', text: 'Invalid OTP please check again.' })
    }

    const handleOTP = (e) => {
        setOtp(e.target.value);
    }

    const handleRePassword = (e) => {
        setRePassword(e.target.value);
    }

    const handleRePasswordNew = (e) => {
        setRePasswordNew(e.target.value);
    }

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        if (rePassword !== rePasswordNew)
            AleartMessage({ icon: 'error', title: 'Not Matching', text: 'Password not matching' })
        else {
            showLoading(true);
            const emailAddress = localStorage.getItem('email');
            const otp = localStorage.getItem('lkt');
            const response = await changePassword(rePassword, emailAddress, otp);
            showLoading(false);
            if (response) {
                AleartMessage({ icon: 'success', title: 'Success', text: 'Password changed' });
                setEnable(1);
            }else{
                AleartMessage({ icon: 'error', title: 'Failed', text: 'Unauthorized Access'});
                setEnable(1);
            }

        }
    }

    return (
        <section className="h-100 gradient-form"
            style={{ backgroundImage: `url(${wallpaper})`, height: "100%", backgroundSize: "cover", backgroundPosition: "center" }}>
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-xl-10">
                        {loading && (
                            <div className="z-10 mt-10 animation" id="container">
                                <svg viewBox="0 0 100 100">
                                    <defs>
                                        <filter id="shadow">
                                            <feDropShadow dx="0" dy="0" stdDeviation="1.5" floodColor="#fc6767" />
                                        </filter>
                                    </defs>
                                    <circle
                                        id="spinner"
                                        style={{
                                            fill: 'transparent',
                                            stroke: '#dd2476',
                                            strokeWidth: '4px',
                                            strokeLinecap: 'round',
                                            filter: 'url(#shadow)',
                                        }} cx="50" cy="50" r="45" />
                                </svg>
                            </div>
                        )}
                        <div className={`${loading && "blur-sm"} card z-0 rounded-3 text-black`}>
                            <div className="row g-0">
                                <div className="col-lg-6">
                                    <div className="card-body p-md-5 mx-md-4">
                                        <div className="flex flex-wrap -mx-3 mb-5 removable">
                                            <div className="w-full max-w-full px-3 mb-6 sm:w-full sm:flex-none xl:mb-0 drop-zone">
                                                <div className="flex items-center justify-center">
                                                    <img src={logo} style={{ width: "185px" }} alt="logo" />
                                                </div>
                                                <div className="flex items-center justify-center">
                                                    <h4 className="mt-1 mb-5 pb-1"
                                                        style={{ fontFamily: "'Great Vibes', cursive", fontSize: "40px", fontWeight: "bold" }}>
                                                        ZenithWave Solutions
                                                    </h4>
                                                </div>
                                                <div className="w-full max-w-full px-3 mb-6 sm:w-full sm:flex-none xl:mb-0 drop-zone">
                                                    {enable === 1 && (
                                                        <form onSubmit={handleSubmit}>
                                                            <h1 className="text-gray-500 text-left" style={{ fontFamily: "'Newsreader', serif", fontSize: "23px", fontWeight: "bold" }}>
                                                                Please login to your account
                                                            </h1>
                                                            <div className="relative flex flex-wrap items-stretch w-full transition-all rounded-lg ease-soft">
                                                                <div className={"relative flex flex-wrap items-stretch w-80 transition-all rounded-lg ease-soft"}>
                                                                    <span className="text-sm ease-soft leading-5 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none  bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                                                                        <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                    </span>
                                                                    <input type="email"
                                                                        className="pl-9 text-sm focus:shadow-soft-primary-outline ease-soft w-30 leading-5 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none focus:transition-shadow"
                                                                        placeholder="Enter Your Email Here..."
                                                                        required
                                                                        onChange={handleEmailChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="relative flex flex-wrap items-stretch w-full transition-all rounded-lg ease-soft">
                                                                <div className={"relative flex flex-wrap items-stretch w-80 transition-all rounded-lg ease-soft"}>
                                                                    <span className="text-sm ease-soft leading-5 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none  bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                                                                        <i className="fa fa-key" aria-hidden="true"></i>
                                                                    </span>
                                                                    <input type="password"
                                                                        className="pl-9 text-sm focus:shadow-soft-primary-outline ease-soft w-30 leading-5 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none focus:transition-shadow"
                                                                        placeholder="Enter Your Password Here..."
                                                                        required
                                                                        onChange={handlePasswordChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {error !== "" && (
                                                                <div className="flex items-center p-2 -mb-7 mt-2 text-sm w-11/12 text-red-900 rounded-xl bg-red-100 dark:bg-gray-800 dark:text-red-400" role="alert">
                                                                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                                                    </svg>
                                                                    <span className="sr-only">Info</span>
                                                                    <div>
                                                                        <span className="font-medium">{error}</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <button type="submit" disabled={loading} className="items-center justify-center relative flex top-10 mr-3 w-11/12 inline-block px-6 py-3 font-bold text-center bg-gradient-to-tl from-purple-700 to-pink-500 uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white">
                                                                LOGIN </button>
                                                            <button onClick={() => handleFormEnable(2)} className="items-center justify-center relative flex top-12 mr-3 w-11/12 inline-block px-6 py-2.5 font-bold text-center bg-gradient-to-tl from-blue-500 to-lime-500 uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white">
                                                                Forgot Password </button>
                                                        </form>
                                                    )}
                                                    {(enable === 2 || enable === 3) && (
                                                        <div>
                                                            <form onSubmit={sendOTP}>
                                                                <h1 className="text-gray-500 text-left" style={{ fontFamily: "'Newsreader', serif", fontSize: "23px", fontWeight: "bold" }}>
                                                                    Password Reset
                                                                </h1>
                                                                <div className="relative flex flex-wrap items-stretch w-full transition-all rounded-lg ease-soft">
                                                                    <div className={"relative flex flex-wrap items-stretch w-80 transition-all rounded-lg ease-soft"}>
                                                                        <span className="text-sm ease-soft leading-5 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none  bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                                                                            <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                        </span>
                                                                        <input type="email"
                                                                            className="pl-9 text-sm focus:shadow-soft-primary-outline ease-soft w-30 leading-5 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none focus:transition-shadow"
                                                                            placeholder="Enter Your Email Here..."
                                                                            required
                                                                            onChange={handleEmailChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <button type="submit" className="items-center justify-center relative flex mt-3 mr-3 w-11/12 inline-block px-6 py-3 font-bold text-center bg-gradient-to-tl from-purple-700 to-pink-500 uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white">
                                                                    Request OTP </button>
                                                            </form>
                                                        </div>
                                                    )}
                                                    {enable === 3 && (
                                                        <div>
                                                            <div className="flex items-center">
                                                                <div className="border-t border-gray-300 flex-grow my-2"></div>
                                                                <p className="text-sm text-gray-600 font-semibold mt-2 mx-4">Enter OTP</p>
                                                                <div className="border-t border-gray-300 flex-grow my-2"></div>
                                                            </div>
                                                            <form onSubmit={HandlecheckOTP}>
                                                                <div className="relative flex flex-wrap items-stretch w-full transition-all rounded-lg ease-soft">
                                                                    <div className={"relative flex flex-wrap items-stretch w-80 transition-all rounded-lg ease-soft"}>
                                                                        <span className="text-sm ease-soft leading-5 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none  bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                                                                            <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                        </span>
                                                                        <input type="text"
                                                                            className="pl-9 text-sm focus:shadow-soft-primary-outline ease-soft w-30 leading-5 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none focus:transition-shadow"
                                                                            placeholder="Enter OTP Here..."
                                                                            required
                                                                            onChange={handleOTP}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <button type="submit" disabled={loading} className="items-center justify-center relative flex mt-3 mr-3 w-11/12 inline-block px-6 py-3 font-bold text-center bg-gradient-to-tl from-blue-700 to-green-500 uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white">
                                                                    Submit OTP </button>
                                                            </form>
                                                        </div>
                                                    )}
                                                    {enable === 4 && (
                                                        <div>
                                                            <form onSubmit={handleForgotPassword}>
                                                                <h1 className="text-gray-500 text-left" style={{ fontFamily: "'Newsreader', serif", fontSize: "23px", fontWeight: "bold" }}>
                                                                    Enter the new password
                                                                </h1>
                                                                <div className="relative flex flex-wrap items-stretch w-full transition-all rounded-lg ease-soft">
                                                                    <div className={"relative flex flex-wrap items-stretch mb-2 w-80 transition-all rounded-lg ease-soft"}>
                                                                        <span className="text-sm ease-soft leading-5 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none  bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                                                                            <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                        </span>
                                                                        <input type="password"
                                                                            className="pl-9 text-sm focus:shadow-soft-primary-outline ease-soft w-30 leading-5 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none focus:transition-shadow"
                                                                            placeholder="Enter the password ..."
                                                                            required
                                                                            onChange={handleRePassword}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="relative flex flex-wrap items-stretch w-full transition-all rounded-lg ease-soft">
                                                                    <div className={"relative flex flex-wrap items-stretch w-80 transition-all rounded-lg ease-soft"}>
                                                                        <span className="text-sm ease-soft leading-5 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none  bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                                                                            <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                        </span>
                                                                        <input type="password"
                                                                            className="pl-9 text-sm focus:shadow-soft-primary-outline ease-soft w-30 leading-5 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none focus:transition-shadow"
                                                                            placeholder="Re-Enter the password ..."
                                                                            required
                                                                            onChange={handleRePasswordNew}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <button type="submit" className="items-center justify-center relative flex mt-3 mr-3 w-11/12 inline-block px-6 py-3 font-bold text-center bg-gradient-to-tl from-blue-500 to-green-600 uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white">
                                                                    Change password </button>
                                                            </form>
                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                                    <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                                        <div className="image_banner">
                                            <img src={banner} className="border-around" alt="banner" />
                                            <h4 className="mb-4" style={{ fontFamily: "'Newsreader', serif", fontWeight: "bold" }}>
                                                Effortlessly Reach Your Audience
                                            </h4>
                                            <p className="small mb-0" style={{ fontFamily: "'Newsreader', serif", fontSize: "20px" }}>
                                                At ZenithWave Solutions, we strive to provide a user-friendly, all-in-one communication
                                                platform that revolutionizes how businesses connect with their audience, fostering
                                                meaningful relationships and driving success in the digital landscape.
                                            </p>
                                            <h4 style={{ fontFamily: "'Newsreader', serif", fontSize: "20px", marginLeft: "200px" }}>~ZenithWave Solutions</h4>
                                            <h4 style={{ fontFamily: "'Newsreader', serif", fontSize: "20px", marginLeft: "115px" }}>~Contact Number: +94778717159</h4>
                                            <h4 style={{ fontFamily: "'Newsreader', serif", fontSize: "20px", marginLeft: "90px" }}>~Email: info@zenithwavesolutions.com</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LoginPage;
