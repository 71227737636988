import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from './constants';
import PaymentButton from './PaymentButton';
import { AlertTimer } from './Aleart';

const PaymentComponent = ({ amount, actual, token }) => {
    const [heading, setHeading] = useState('Please Complete your payment');
   
    const handlePaymentSuccess = (details) => {
        setHeading('Please wait... Do not refresh the page');
        axios.post(`${API_BASE_URL}/api/v1/payment/success?amount=${actual}`, details, {
            headers: {
                'Authorization': `Bearer ${token}`, // Correctly formatted header
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                AlertTimer({ title: 'Payment Processing', html: 'Payment will complete in <b></b> milliseconds.', location: '/phone-manager/payment-gateway' });
            })
            .catch(error => {
                console.error('Error:', error);
            });

    };

    const handlePaymentError = (error) => {
        console.error('Payment Error', error);
    };

    return (
        <div>
            <h5>{heading}</h5>
            <PaymentButton
                clientID="AZTDTiUc_3m5wms9ol8p0UU7ws7CcNTu6zSe4bRVblyrb9Q5o0JHo3E2LnUz37mRQCVjBNRIKdi7F0kJ"
                amount={amount}
                currency="USD"
                onSuccess={handlePaymentSuccess}
                onError={handlePaymentError}
            />
        </div>
    );
};

export default PaymentComponent;
