import React, { useEffect, useState } from 'react';
import './assets/css/tailwind/tailwind.min.css';
import './assets/css/theme.css';
import './assets/css/loopple/loopple.css';
import { SideNav, TopNav } from '../component/SideNav';
import { DashboardTable } from '../component/DashbordTable';
import { GetAllProjects } from '../api/project';
import '../css/other.css';
import { useNavigate } from 'react-router-dom';
import { CreateProject } from '../component/CreateProject';
import {  validateTokenNew } from '../auth/authentication';
import InfinityLoader from '../component/COMP/loadingPage';

const MainPage = () => {
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const storedDataList = JSON.parse(localStorage.getItem('dataList'));
            if (storedDataList !== null) {
                setDataList(storedDataList);
                return;
            }
            setLoading(true);
            const data = await GetAllProjects(token);
            localStorage.setItem('dataList', JSON.stringify(data));
            setDataList(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect( () => {
        const fetchDataAndValidateToken =  async () => {
            await validateTokenNew(navigate, token, "WHATSAPP");
            setEnabled(true);
            fetchData();
        };
        fetchDataAndValidateToken();
    }, [navigate, token]);

    const totalProjects = dataList.length;
    const completedCount = dataList.filter(item => item.status === 'COMPLETED').length;
    const inProgressCount = dataList.filter(item => item.status === 'IN_PROGRESS').length;
    const PendingCount = dataList.filter(item => item.status === 'PENDING').length;

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlePageReload = () => {
        localStorage.removeItem('dataList');
        window.location.reload();
    };

    const filteredData = searchQuery
        ? dataList.filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()))
        : dataList;

    const handleCreateProjectClick = () => {
        setIsModalVisible(true);
        localStorage.removeItem("phoneNumberDetails");
    };

    return (
        <div>
            {enabled && (
                <div className={isModalVisible ? "overflow-hidden" : ""}>
                    <SideNav item={1} />
                    <div className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200" id="panel">
                        <TopNav companyName={"ZenithWave Solutions"} />
                        {loading ? <div><InfinityLoader /></div> : (
                            <div className="w-full px-6 py-6 mx-auto loopple-min-height-78vh text-slate-500">
                                <div className={`${isModalVisible ? "blur-lg" : ""} flex flex-wrap -mx-3 removable`}>
                                    <div className="w-full max-w-full px-3 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
                                        <div className="relative flex flex-col min-w-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border bg-gradient-to-tl from-gray-900 to-slate-800 mb-4">
                                            <div className="flex-auto p-4">
                                                <div className="flex flex-row -mx-3">
                                                    <div className="flex-none w-2/3 max-w-full px-3">
                                                        <div>
                                                            <p className="mb-0 font-sans font-semibold leading-normal text-sm text-white">Total Projects</p>
                                                            <h5 className="mb-0 font-bold text-green-500">{totalProjects}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="px-3 text-right basis-1/3">
                                                        <div className="inline-block w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-purple-700 to-pink-500">
                                                            <i className="ni ni-money-coins text-lg relative top-3.5 text-white"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full max-w-full px-3 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
                                        <div className="relative flex flex-col min-w-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border bg-gradient-to-tl from-gray-900 to-slate-800 mb-4">
                                            <div className="flex-auto p-4">
                                                <div className="flex flex-row -mx-3">
                                                    <div className="flex-none w-2/3 max-w-full px-3">
                                                        <div>
                                                            <p className="mb-0 font-sans font-semibold leading-normal text-sm text-white">Completed</p>
                                                            <h5 className="mb-0 font-bold text-lime-500">{completedCount}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="px-3 text-right basis-1/3">
                                                        <div className="inline-block w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-purple-700 to-pink-500">
                                                            <i className="ni ni-world text-lg relative top-3.5 text-white"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full max-w-full px-3 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
                                        <div className="relative flex flex-col min-w-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border bg-gradient-to-tl from-gray-900 to-slate-800 mb-4">
                                            <div className="flex-auto p-4">
                                                <div className="flex flex-row -mx-3">
                                                    <div className="flex-none w-2/3 max-w-full px-3">
                                                        <div>
                                                            <p className="mb-0 font-sans font-semibold leading-normal text-sm text-white">IN-PROGRESS</p>
                                                            <h5 className="mb-0 font-bold text-yellow-500">{inProgressCount}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="px-3 text-right basis-1/3">
                                                        <div className="inline-block w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-purple-700 to-pink-500">
                                                            <i className="ni ni-paper-diploma text-lg relative top-3.5 text-white"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full max-w-full px-3 sm:w-1/2 sm:flex-none xl:w-1/4">
                                        <div className="relative flex flex-col min-w-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border bg-gradient-to-tl from-gray-900 to-slate-800 mb-4">
                                            <div className="flex-auto p-4">
                                                <div className="flex flex-row -mx-3">
                                                    <div className="flex-none w-2/3 max-w-full px-3">
                                                        <div>
                                                            <p className="mb-0 font-sans font-semibold leading-normal text-sm text-white">PENDING</p>
                                                            <h5 className="mb-0 font-bold text-red-500"> {PendingCount}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="px-3 text-right basis-1/3">
                                                        <div className="inline-block w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-purple-700 to-pink-500">
                                                            <i className="ni ni-cart text-lg relative top-3.5 text-white"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border mb-4">
                                    <div className={`${isModalVisible ? "blur-lg" : ""} p-6 pb-0 mb-0 bg-white rounded-t-2xl flex justify-between`}>
                                        <h6>Projects
                                            <div className={"relative flex flex-wrap items-stretch w-80 transition-all rounded-lg ease-soft"}>
                                                <span className="text-sm ease-soft leading-5 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none  bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                                                    <i className="fas fa-search" aria-hidden="true"></i>
                                                </span>
                                                <input type="text"
                                                    className="pl-9 text-sm focus:shadow-soft-primary-outline ease-soft w-30 leading-5 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none focus:transition-shadow"
                                                    placeholder="Type here..."
                                                    value={searchQuery}
                                                    disabled={isModalVisible}
                                                    onChange={handleSearchChange}
                                                />
                                            </div>
                                        </h6>
                                        <div className='h-full'>
                                            <button type="button" onClick={handlePageReload}
                                                disabled={isModalVisible}
                                                className="mr-3 inline-block px-6 py-3 font-bold text-center bg-gradient-to-tl from-blue-700 to-green-500 uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white">
                                                <i className="fa fa-refresh"></i>
                                            </button>
                                            <button type="button"
                                                disabled={isModalVisible}
                                                onClick={handleCreateProjectClick}
                                                className="mr-3 inline-block px-6 py-3 font-bold text-center bg-gradient-to-tl from-purple-700 to-pink-500 uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white">
                                                Create Project
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex-auto px-0 pt-0 pb-2">
                                        <div className="p-0 overflow-x-auto">
                                            {filteredData.length <= 0 ? <div className={`${isModalVisible ? "blur-sm" : ""} relative w-full p-2 text-white bg-yellow-500 rounded-lg`}>No records were found</div>
                                                : <DashboardTable isModalVisible={isModalVisible} dataList={filteredData} />
                                            }
                                            {isModalVisible && <CreateProject token={token} setIsModalVisible={setIsModalVisible} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MainPage;
