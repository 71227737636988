import React from "react";

const Pagination = (totalPages, currentPage, handlePageChange) => {
    const maxVisiblePages = 7;
    const pageButtons = [];
    let startPage, endPage;

    if (totalPages <= maxVisiblePages) {
        startPage = 1;
        endPage = totalPages;
    } else {
        if (currentPage <= maxVisiblePages - Math.floor(maxVisiblePages / 2)) {
            startPage = 1;
            endPage = maxVisiblePages;
        } else if (currentPage + Math.floor(maxVisiblePages / 2) >= totalPages) {
            startPage = totalPages - maxVisiblePages + 1;
            endPage = totalPages;
        } else {
            startPage = currentPage - Math.floor(maxVisiblePages / 2);
            endPage = currentPage + Math.floor(maxVisiblePages / 2);
        }
    }

    for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
            <li key={i}>
                <button
                    onClick={() => handlePageChange(i)}
                    className={`first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid ${currentPage === i
                        ? 'border-pink-500 text-white bg-pink-500'
                        : 'border-pink-500 bg-white text-pink-500'
                        }`}
                >
                    {i}
                </button>
            </li>
        );
    }

    return pageButtons;
}

export {Pagination}