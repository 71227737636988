import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { API_BASE_URL } from "../component/constants";

const WebSocketService = () => {
    const socket = new SockJS(`${API_BASE_URL}/ws`);
    const stompClient = Stomp.over(socket);

    stompClient.debug = () => {};

    const connect = (onConnectCallback, onErrorCallback) => {
        stompClient.connect(
            {},
            () => {
                if (onConnectCallback) {
                    onConnectCallback();
                }
            },
            (error) => {
                console.error('Error connecting to WebSocket:', error);
                if (onErrorCallback) {
                    onErrorCallback(error);
                }
            }
        );
    };

    const subscribeToProgress = (callback) => {
        stompClient.subscribe('/topic/progress', (message) => {
            const newProgress = JSON.parse(message.body);
            if (callback) {
                callback(newProgress);
            }
        });
    };

    const subscribeToBalance = (callback) => {
        stompClient.subscribe('/balance/amount', (message) => { // Subscribe to the new "/balance" topic
            const newBalance = JSON.parse(message.body);
            if (callback) {
                callback(newBalance);
            }
        });
    };

    return {
        connect,
        subscribeToProgress,
        subscribeToBalance
    };
};

export default WebSocketService;

