import React, { useEffect, useState } from "react";
import { CreateTemplate, GetPhoneNumbers, GetUploadFileId } from "../3rdParty/whatsapp";

const Page1 = ({ setIsModalVisible, formData, handleInputChange, handlePageForwardClick }) => {
    const isModalVisible = () => {
        setIsModalVisible(false);
    }
    const next = (formData.projectDescription === "" || formData.projectName === "")
    return (
        <div className="flex flex-col mt-8 h-full h-[520px]">
            <div className="absolute top-8 left-0 flex flex-col visible w-full h-auto min-w-0 p-4 break-words bg-white border-0 opacity-100 dark:bg-grey-950 dark:shadow-dark-xl shadow-xl drop-shadow-2xl rounded-2xl bg-clip-border">
                <div className="flex flex-wrap -mx-3 text-center">
                    <div className="w-10/12 max-w-full px-3 mx-auto [flex:0_0_auto]">
                        <h5 className="text-slate-500">Let's start with the basic information</h5>
                        <p className="text-[#67748e]">Let us know your project details. The project name should be unique</p>
                    </div>
                </div>
                <div className="flex flex-wrap mt-4 -mx-3">
                    <div className="w-full max-w-full px-3 mb-6 lg:w-4/12 sm:flex-none xl:mb-0 drop-zone">
                        <img src="https://cdn-cashy-static-assets.lucidchart.com/marketing/blog/2019Q1/why-pm-is-important/project-management-header.png" alt="Project Management" className="w-full h-full object-cover" />
                    </div>
                    <div className="w-full max-w-full px-3 mb-6 lg:w-8/12 sm:flex-none xl:mb-0 drop-zone">
                        <div className="relative absolute h-2/3 inset-x-0 bottom-0 ">
                            <h6 className="mb-2 ml-1 w-full font-bold text-slate-700 dark:text-white/80 text-left">Name of the Project</h6>
                            <input
                                type="text"
                                name="projectName"
                                value={formData.projectName}
                                onChange={handleInputChange}
                                placeholder="Project Name ..."
                                className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                maxLength={100}
                            />
                        </div>
                        <div className="relative absolute h-1/3 inset-x-0 bottom-0 ">
                            <h6 className="mb-2 ml-1 w-full font-bold text-slate-700 dark:text-white/80 text-left">Description</h6>
                            <textarea
                                name="projectDescription"
                                value={formData.projectDescription}
                                onChange={handleInputChange}
                                maxLength={500}
                                placeholder="Write your description here..."
                                className="focus:shadow-soft-primary-outline min-h-unset text-sm leading-5.6 ease-soft block h-auto w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none sm:mt-2"
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap mt-4 -mx-3">
                    <button
                        onClick={isModalVisible}
                        type="button" className="inline-block px-6 py-3 mb-0 font-bold text-right uppercase align-middle transition-all border-0 rounded-lg cursor-pointer hover:scale-[1.02] active:opacity-[.85] hover:shadow-xs bg-gradient-to-tl from-[#ced4da] to-[#ebeff4] leading-pro text-[.75rem] ease-in tracking-tight shadow-md bg-150 bg-x-25 text-[#3a416f]">
                        Close
                    </button>
                    <button
                        type="button"
                        disabled={next}
                        onClick={() => handlePageForwardClick(2)}
                        className="inline-block px-6 py-3 mb-0 ml-auto font-bold text-white uppercase align-middle transition-all border-0 rounded-lg cursor-pointer hover:scale-[1.02] active:opacity-[.85] hover:shadow-xs dark:bg-gradient-to-tl dark:from-slate-850 dark:to-gray-850 bg-gradient-to-tl from-[#141727] to-[#3a416f] leading-pro text-[.75rem] ease-in tracking-tight shadow-md bg-150 bg-x-25"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

const Page2 = ({ token, formData, handleInputChange, handlePageForwardClick }) => {
    const [phoneNumberDetails, setphoneNumberDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedDataString = localStorage.getItem('phoneNumberDetails');

                let storedDataList = [];
                if (storedDataString && storedDataString !== "undefined") {
                    storedDataList = JSON.parse(storedDataString);
                }

                if (storedDataList.length > 0) {
                    setphoneNumberDetails(storedDataList);
                } else {
                    setLoading(true);
                    const payload = await GetPhoneNumbers(token);

                    if (payload) {
                        setphoneNumberDetails(payload);
                        localStorage.setItem('phoneNumberDetails', JSON.stringify(payload));
                    }
                }
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [token]);

    const next = (formData.selectedBusinessAccount === "" || formData.selectedPhoneNumber === "");

    return (
        <div className="flex flex-col mt-8">
            <div
                className="absolute top-10 left-0 flex flex-col visible w-full h-auto min-w-0 p-4 break-words bg-white border-0 opacity-100 dark:bg-grey-950 dark:shadow-dark-xl shadow-xl drop-shadow-2xl rounded-2xl bg-clip-border">
                <div className="flex flex-wrap -mx-3 text-center">
                    <div className="w-10/12 max-w-full px-3 mx-auto [flex:0_0_auto]">
                        <h5 className="text-slate-500">Business Manager Details</h5>
                        <p className="text-[#67748e]">{loading ? <b className="text-red-700"> Please Wait content is loading...</b> : "Select the WhatsApp Account and Phone Number."}</p>
                    </div>
                </div>
                <div>
                    <div className="flex flex-wrap mt-4 -mx-3">
                        <div className="w-full max-w-full px-3 mb-6 lg:w-4/12 sm:flex-none xl:mb-0 drop-zone">
                            <img src="https://cdn-cashy-static-assets.lucidchart.com/marketing/blog/2019Q1/why-pm-is-important/project-management-header.png" alt="Project Management" className="w-full h-full object-cover" />
                        </div>
                        <div className="w-full max-w-full px-3 mb-6 lg:w-8/12 sm:flex-none xl:mb-0 drop-zone">
                            <div className="relative absolute h-2/3 inset-x-0 bottom-0">
                                <h6 className="mb-2 ml-1 w-full font-bold text-slate-700 dark:text-white/80 text-left">
                                    Select the Business Account
                                </h6>
                                <select
                                    name="selectedBusinessAccount"
                                    value={formData.selectedBusinessAccount}
                                    onChange={handleInputChange}
                                    disabled={loading}
                                    className={`${loading && "blur-sm"} bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-2 mr-4`}
                                >
                                    <option value="">None</option>
                                    {phoneNumberDetails &&
                                        phoneNumberDetails.map((businessDetails) => (
                                            <option key={businessDetails.business.businessId} value={businessDetails.business.businessId}>
                                                {businessDetails.business.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="relative absolute h-1/3 inset-x-0 bottom-0">
                                <h6 className="mb-2 ml-1 w-full font-bold text-slate-700 dark:text-white/80 text-left">
                                    Select Phone Number
                                </h6>
                                <select
                                    name="selectedPhoneNumber"
                                    value={formData.selectedPhoneNumber}
                                    onChange={handleInputChange}
                                    disabled={loading}
                                    className={`${loading && "blur-sm"} bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-2 mr-4`}
                                >
                                    <option value="">None</option>
                                    {phoneNumberDetails &&
                                        phoneNumberDetails.map((phoneNumberDetail) => {
                                            if (formData.selectedBusinessAccount === phoneNumberDetail.business.businessId) {
                                                return (
                                                    <option key={phoneNumberDetail.phoneId} value={phoneNumberDetail.phoneId}>
                                                        {phoneNumberDetail.number + "  |  " + phoneNumberDetail.name + "  |  " + phoneNumberDetail.phoneId}
                                                    </option>
                                                );
                                            }
                                            return null;
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap mt-4 -mx-3">
                        <button
                            onClick={() => handlePageForwardClick(1)}
                            type="button" className="inline-block px-6 py-3 mb-0 font-bold text-right uppercase align-middle transition-all border-0 rounded-lg cursor-pointer hover:scale-[1.02] active:opacity-[.85] hover:shadow-xs bg-gradient-to-tl from-[#ced4da] to-[#ebeff4] leading-pro text-[.75rem] ease-in tracking-tight shadow-md bg-150 bg-x-25 text-[#3a416f]">
                            Prev
                        </button>
                        <button type="button"
                            onClick={() => handlePageForwardClick(3)}
                            disabled={next}
                            className={`inline-block px-6 py-3 mb-0 ml-auto font-bold text-right text-white uppercase align-middle transition-all border-0 rounded-lg cursor-pointer hover:scale-[1.02] active:opacity-[.85] hover:shadow-xs dark:bg-gradient-to-tl dark:from-slate-850 dark:to-gray-850 bg-gradient-to-tl from-[#141727] to-[#3a416f] leading-pro text-[.75rem] ease-in tracking-tight shadow-md bg-150 bg-x-25`}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Page3 = ({ setIsModalVisible, totalCount, handleFileChange, handleUpload, isUploadDisabled, handleCheck, isChecked, closeModal, isModalOpen, openModal, handlePageForwardClick, progress }) => {
    let value = Math.round((progress / totalCount) * 100);
    const isModalVisible = () => {
        if (progress === totalCount) {
            localStorage.removeItem('dataList');
            setIsModalVisible(false);
        }


    }
    return (
        <div style={{ zIndex: 1000, position: 'relative' }}
            className="absolute top-20 left-0 flex flex-col visible w-full h-auto min-w-0 p-4 break-words bg-white border-0 opacity-100 dark:bg-grey-950 dark:shadow-dark-xl shadow-xl drop-shadow-2xl rounded-2xl bg-clip-border">
            <div className="flex flex-wrap -mx-3 text-center">
                <div className="w-10/12 max-w-full px-3 mx-auto [flex:0_0_auto]">
                    <h5 className="text-slate-500">Prospect Manager Details</h5>
                    <p className="text-[#67748e]">Upload the prospects Details</p>
                </div>
            </div>
            <div>
                <div className="flex flex-wrap mt-4 -mx-3">
                    <div className="w-full max-w-full px-3 mb-6 lg:w-4/12 sm:flex-none xl:mb-0 drop-zone">
                        <img src="https://cdn-cashy-static-assets.lucidchart.com/marketing/blog/2019Q1/why-pm-is-important/project-management-header.png" alt="Project Management" className="w-full h-full object-cover" />
                    </div>
                    <div className="w-full max-w-full px-3 mb-6 lg:w-8/12 sm:flex-none xl:mb-0 drop-zone">
                        <div className="relative h-32 absolute inset-x-0 bottom-0 h-16" >
                            <button onClick={() => window.location.href = "https://drive.google.com/uc?export=download&id=1_xwPi0ecReB-3e5rtj877LPfiB2K34GJ"}
                                type="button" className="inline-block w-full px-8 py-3 mr-3 font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-gradient-to-tl from-blue-700 to-lime-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs">Download the Sample File</button>
                        </div>
                        <div className="relative h-32 absolute inset-x-0 bottom-0 h-16" >
                            <div className="relative">
                                <button
                                    type="button"
                                    onClick={openModal}
                                    className="inline-block w-full px-8 py-3 font-bold text-center uppercase align-middle transition-all bg-transparent border border-solid rounded-lg shadow-none cursor-pointer active:opacity-85 leading-pro text-xs ease-soft-in tracking-tight-soft bg-150 bg-x-25 hover:scale-102 active:shadow-soft-xs border-fuchsia-500 text-fuchsia-500 hover:text-fuchsia-500 hover:opacity-75 hover:shadow-none active:scale-100 active:border-fuchsia-500 active:bg-fuchsia-500 active:text-white hover:active:border-fuchsia-500 hover:active:bg-transparent hover:active:text-fuchsia-500 hover:active:opacity-75"
                                >
                                    Import File
                                </button>
                                <div style={{ zIndex: 1100, position: 'relative' }}
                                    className={`fixed top-1/2 left-1/2 w-4/6 transform -translate-x-2/3 -translate-y-2/3 ${isModalOpen ? 'block' : 'hidden'
                                        } w-1/3 h-1/2 overflow-hidden transition-opacity ease-linear opacity-100 z-sticky outline-0`}
                                    id="import"
                                    aria-hidden="true"
                                >
                                    <div className="relative w-auto m-2 transition-transform duration-300 pointer-events-none sm:m-7 sm:max-w-125 sm:mx-auto lg:mt-48 ease-soft-out -translate-y-13">
                                        <div className="relative flex flex-col w-full bg-white border border-solid pointer-events-auto dark:bg-gray-950 bg-clip-padding border-black/20 rounded-xl outline-0">
                                            <div className="flex items-center justify-between p-4 border-b border-solid shrink-0 border-slate-100 rounded-t-xl">
                                                <h5 className="mb-0 leading-normal dark:text-back" id="ModalLabel">
                                                    Import .XLSX
                                                </h5>
                                                <i className="ml-4 fas fa-upload"></i>
                                                <button
                                                    type="button"
                                                    onClick={closeModal}
                                                    className="fa fa-close w-4 h-4 ml-auto box-content p-2 text-black dark:text-white border-0 rounded-1.5 opacity-50 cursor-pointer -m-2"
                                                    data-dismiss="modal"
                                                ></button>
                                            </div>
                                            <div className="relative flex-auto p-4">
                                                <p>You can browse your computer for a file.</p>
                                                <input
                                                    type="file"
                                                    onChange={handleFileChange}
                                                    accept=".xlsx"
                                                    placeholder="Browse file..."
                                                    className={`dark:bg-gray-950 mb-4 focus:shadow-soft-primary-outline dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none`}
                                                />
                                                <div className="min-h-6 pl-7 mb-0.5 block">
                                                    <input
                                                        type="checkbox"
                                                        checked={isChecked}
                                                        onChange={handleCheck}
                                                        id="importCheck"
                                                        className="w-5 h-5 ease-soft -ml-7 rounded-1.4 checked:bg-gradient-to-tl checked:from-gray-900 checked:to-slate-800 after:text-xxs after:font-awesome after:duration-250 after:ease-soft-in-out duration-250 relative float-left mt-1 cursor-pointer appearance-none border border-solid border-slate-150 bg-white bg-contain bg-center bg-no-repeat align-top transition-all after:absolute after:flex after:h-full after:w-full after:items-center after:justify-center after:text-white after:opacity-0 after:transition-all after:content-['\f00c'] checked:border-0 checked:border-transparent checked:bg-transparent checked:after:opacity-100"
                                                    />
                                                    <label
                                                        htmlFor="importCheck"
                                                        className="inline-block mb-2 ml-1 font-bold cursor-pointer select-none text-xs text-slate-700 dark:text-white/80"
                                                    >
                                                        I accept the terms and conditions
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap items-center justify-end p-3 border-t border-solid shrink-0 border-slate-100 rounded-b-xl">
                                                <button
                                                    type="button"
                                                    onClick={closeModal}
                                                    className="inline-block px-8 py-2 m-1 mb-4 text-xs font-bold text-center text-white uppercase align-middle transition-all border-0 rounded-lg cursor-pointer ease-soft-in leading-pro tracking-tight-soft bg-gradient-to-tl from-slate-600 to-slate-300 shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85"
                                                >
                                                    Close
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={handleUpload}
                                                    disabled={isUploadDisabled}
                                                    className="inline-block px-8 py-2 m-1 mb-4 text-xs font-bold text-center text-white uppercase align-middle transition-all border-0 rounded-lg cursor-pointer ease-soft-in leading-pro tracking-tight-soft bg-gradient-to-tl from-purple-700 to-pink-500 shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85"
                                                >
                                                    Upload
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(
                            totalCount > 0 && (
                                <div className="relative h-32 absolute inset-x-0 bottom-0 h-16" >
                                    <div className="w-full">
                                        <div className="flex mb-2">
                                            <span className="mr-2 font-semibold leading-normal capitalize animate-charcter text-sm">{progress === totalCount ? "UPLOAD COMPLEATED" : "PLEASE WAIT ... DO NOT REFRESH THE PAGE.."}</span>
                                            <span className="ml-auto font-semibold leading-normal text-sm">{value}%</span>
                                        </div>
                                        <div>
                                            <div className="h-0.75 text-xs flex overflow-visible rounded-lg bg-gray-200">
                                                <div style={{ width: `${value}%` }} className="bg-fuchsia-500 transition-width duration-600 ease-soft rounded-1 -mt-0.4 -ml-px flex h-1.5 flex-col justify-center overflow-hidden whitespace-nowrap text-center text-white"></div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="flex mb-2 items-center justify-center">
                                            <span className="mr-2 font-semibold items-center leading-normal capitalize text-sm">{progress} / {totalCount} Records</span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="flex flex-wrap mt-4 -mx-3">
                    <button type="button"
                        onClick={() => handlePageForwardClick(2)}
                        className="inline-block px-6 py-3 mb-0 font-bold text-right uppercase align-middle transition-all border-0 rounded-lg cursor-pointer hover:scale-[1.02] active:opacity-[.85] hover:shadow-xs bg-gradient-to-tl from-[#ced4da] to-[#ebeff4] leading-pro text-[.75rem] ease-in tracking-tight shadow-md bg-150 bg-x-25 text-[#3a416f]">
                        Prev
                    </button>
                    <button type="button"
                        disabled={((progress === totalCount && totalCount !== 0) ? false : true)}
                        onClick={isModalVisible}
                        className="inline-block px-6 py-3 mb-0 ml-auto font-bold text-right text-white bg-gradient-to-tl from-purple-700 to-pink-500 uppercase align-middle transition-all border-0 rounded-lg cursor-pointer hover:scale-[1.02] active:opacity-[.85] hover:shadow-xs dark:bg-gradient-to-tl dark:from-slate-850 dark:to-gray-850 bg-gradient-to-tl from-[#141727] to-[#3a416f] leading-pro text-[.75rem] ease-in tracking-tight shadow-md bg-150 bg-x-25">
                        Complete Project
                    </button>
                </div>
            </div>
        </div>
    );
};


const HeaderTemplate = ({ formData, handleInputChange, handleRadioChange, selectedFile, errorMessage, handleFileChange, handleHeader, header, addHeaderVariable, handleHeaderValue, radioButton }) => {
    return (
        <div className="relative flex flex-col min-w-0 mb-2 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border drop-shadow-lg ">
            <div className="flex-auto p-4 -mb-1 ">
                <div className="flex flex-wrap -mx-3">
                    <div className="flex-none w-3/3 max-w-full px-3">
                        <div>
                            <h5>Header</h5>
                            <h6 className="text-slate-500">Add a 60 character title to your message.
                                Only one variable is
                                supported in the header.</h6>
                        </div>
                    </div>
                    <select
                        name="header"
                        value={formData.header}
                        onChange={handleInputChange}
                        className={`-mb-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-2 mr-1`}
                    >
                        <option selected="">None</option>
                        <option value="text">Text</option>
                        <option value="media">Media</option>
                    </select>
                </div>
                <br />
                {formData.header === "media" && (
                    <div>
                        <ul className="grid w-full gap-6 md:grid-cols-3">
                            <li onClick={() => handleRadioChange(1)}>
                                <input type="radio" id="image-option" value="" name="header" className="hidden peer" required="" />
                                <label htmlFor="image-option" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                    <div className="block">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="60" width="180" viewBox="0 0 512 512">
                                            <path d="M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"></path>
                                        </svg>
                                        <div className="w-full text-lg font-semibold text-center">Image</div>
                                    </div>
                                </label>
                            </li>
                            <li onClick={() => handleRadioChange(2)}>
                                <input type="radio" id="video-option" name="header" value="" className="hidden peer" />
                                <label htmlFor="video-option" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                    <div className="block">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="60" width="180" viewBox="0 0 576 512">
                                            <path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2V384c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1V320 192 174.9l14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z"></path>
                                        </svg>
                                        <div className="w-full text-lg font-semibold text-center">Video</div>
                                    </div>
                                </label>
                            </li>
                            <li onClick={() => handleRadioChange(3)}>
                                <input type="radio" id="document-option" name="header" value="" className="hidden peer" />
                                <label htmlFor="document-option" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                    <div className="block">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="60" width="180" viewBox="0 0 384 512">
                                            <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"></path>
                                        </svg>
                                        <div className="w-full text-lg font-semibold text-center">Documents</div>

                                    </div>
                                </label>
                            </li>
                            <br />
                        </ul>
                        <div className="relative flex flex-col min-w-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border drop-shadow-lg">
                            <div className="flex-auto p-4">
                                {radioButton === 1 && (
                                    <label className="w-5/12 border border-sky-500 relative -mt-6 cursor-pointer bg-white rounded-lg p-2 border-dashed border-gray-300 hover:border-gray-400 hover:bg-gray-50">
                                        <div>
                                            <input onChange={(e) => handleFileChange(e, 1)} accept=".jpg, .jpeg, .png"
                                                type="file" className="absolute top-0 left-0 h-full w-full opacity-0 cursor-pointer" />
                                            <span className="text-indigo-600 font-medium">Choose an image</span><span className='mx-3'>{selectedFile ? selectedFile.name : errorMessage ? errorMessage : "No image seletected"}</span>
                                        </div>
                                    </label>
                                )}{radioButton === 2 && (
                                    <label className="w-5/12 border border-sky-500 relative -mt-6 cursor-pointer bg-white rounded-lg p-2 border-dashed border-gray-300 hover:border-gray-400 hover:bg-gray-50">
                                        <div>
                                            <input onChange={(e) => handleFileChange(e, 2)} accpt=".mp4"
                                                type="file" className="absolute top-0 left-0 h-full w-full opacity-0 cursor-pointer" />
                                            <span className="text-indigo-600 font-medium">Choose a video</span><span className='mx-3'>{selectedFile ? selectedFile.name : errorMessage ? errorMessage : "No video seletected"}</span>
                                        </div>
                                    </label>
                                )}{
                                    radioButton === 3 && (
                                        <label className="w-5/12 border border-sky-500 relative -mt-6 cursor-pointer bg-white rounded-lg p-2 border-dashed border-gray-300 hover:border-gray-400 hover:bg-gray-50">
                                            <div>
                                                <input onChange={(e) => handleFileChange(e, 3)} accpt=".doc, .docx"
                                                    type="file" className="absolute top-0 left-0 h-full w-full opacity-0 cursor-pointer" />
                                                <span className="text-indigo-600 font-medium">Choose a document</span><span className='mx-3'>{selectedFile ? selectedFile.name : errorMessage ? errorMessage : "No doc seletected"}</span>
                                            </div>
                                        </label>
                                    )
                                }
                                <div className='mt-2 bg-orange-200'>
                                    <p className='mb-1'><b>Note:</b>Following are the file formats and their respective sizes which are accepted for upload</p>
                                    <p className='mb-1'><b>1.</b> Image: JPEG and PNG <span className='text-red-500'>(Max size 2 MB)</span></p>
                                    <p className='mb-1'><b>2.</b> Video: MP4 <span className='text-red-500'>(Max size 20 MB)</span></p>
                                    <p className='mb-1'><b>3.</b> Document: <span className='text-red-500'>(Max size 2 MB)</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {formData.header === "text" && (
                    <div>
                        <div className="flex-auto p-4 -mb-20">
                            <div className="text-xs text-gray-500">
                                <input type="text" placeholder="Header Text"
                                    onChange={handleHeader}
                                    value={header}
                                    required
                                    className="mx-1 focus:shadow-soft-primary-outline w-full text-sm leading-5.6 ease-soft block appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none ml-2 mr-5 px-4"
                                    maxLength={60}
                                />
                                <span className='absolute mx-1 right-0 h-16 w-16'>{header.length}/60</span>
                                <br />
                                <button
                                    onClick={addHeaderVariable}
                                    disabled={header.includes("{{1}}") ? true : false}
                                    className="text-sm bg-transparent hover:bg-blue-100 text-blue-700 font-semibold py-1 px-2 border border-blue-500 hover:border-transparent rounded mb-7 ml-3">
                                    + Add Variable
                                </button>
                            </div>
                        </div>
                        {header.includes("{{1}}") && (
                            <div className="flex-auto p-1">
                                <div className="flex-none w-2/3 max-w-full px-3">
                                    <h6 className="text-slate-500">Define sample variable value for header</h6>
                                    <div className="flex">
                                        <input type="text" placeholder="Variable"
                                            disabled={true}
                                            value={"{{1}}"}
                                            className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                            style={{ marginRight: "10px" }} />
                                        <input type="text" placeholder="Sample value"
                                            required
                                            className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                            onChange={(e) => handleHeaderValue(e.target.value)}
                                            style={{ marginLeft: "10px" }} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}


const BusinessProfiles = ({ token, setphoneNumberDetails, phoneNumberDetails, businessId, handleInputChange }) => {
    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedDataString = localStorage.getItem('phoneNumberDetails');
                let storedDataList = [];
                if (storedDataString && storedDataString !== "undefined") {
                    storedDataList = JSON.parse(storedDataString);
                }

                if (storedDataList.length > 0) {
                    setphoneNumberDetails(storedDataList);
                } else {
                    const payload = await GetPhoneNumbers(token);

                    if (payload) {
                        setphoneNumberDetails(payload);
                        localStorage.setItem('phoneNumberDetails', JSON.stringify(payload));
                    }
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchData();
    }, [token]);
    return (
        <select
            name="businessId"
            value={businessId}
            required
            onChange={handleInputChange}
            className={`mb-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-2 mr-1`}
        >
            <option value="" disabled>Select a business</option>
            {phoneNumberDetails &&
                phoneNumberDetails.map((businessDetails) => (
                    <option key={businessDetails.business.businessId} value={businessDetails.business.businessId}>
                        {businessDetails.business.name}
                    </option>
                ))}
        </select>
    );
};


const handleSubmitButton = async (token, templateName, formData, header, headerValue, message, variableValues, footer, elements, radioButton, selectedFile, templateType) => {
    const template = {
        name: templateName,
        language: formData.language,
        category: templateType,
        components: []
    };

    if (formData.header === "text") {
        const tempHeader = {
            type: "HEADER",
            format: "TEXT",
            text: header,
            example: header.includes("{{1}}") ? { header_text: [headerValue] } : null
        };
        template.components.push(tempHeader);
    } else if (formData.header === "media" && selectedFile !== null) {
        const mediaType = radioButton === 1 ? "IMAGE" : radioButton === 2 ? "VIDEO" : radioButton === 3 ? "DOCUMENT" : null;

        if (mediaType) {
            const fileUrl = await GetUploadFileId(token, selectedFile, selectedFile.type);
            console.log("fileUrl: " + fileUrl);
            const tempHeader = {
                type: "HEADER",
                format: mediaType,
                example: { header_handle: [fileUrl] }
            };
            template.components.push(tempHeader);
        }
    }

    if (message.length > 0) {
        const body = {
            type: "BODY",
            text: message
        };
        if (message.includes("{{1}}")) {
            body.example = { body_text: [variableValues] }
        }
        template.components.push(body);
    }

    if (footer.length > 0) {
        const foot = { type: "FOOTER", text: footer };
        template.components.push(foot);
    }


    if (elements.length > 0) {
        const buttons = {
            type: "BUTTONS",
            buttons: elements.map(obj => ({
                type: obj.type === 'QUICK_REPLY' ? "QUICK_REPLY" : obj.type,
                text: obj.type === 'QUICK_REPLY' ? obj.value : obj.text,
                ...(obj.type === 'PHONE_NUMBER' && { phone_number: obj.value }),
                ...(obj.type === 'URL' && { url: obj.value })
            }))
        };
        template.components.push(buttons);
    }
    const jsonString = JSON.stringify(template);
    const res = await CreateTemplate(token, formData.businessId, jsonString);
    return res;
}

export { Page1, Page2, Page3, HeaderTemplate, handleSubmitButton, BusinessProfiles };