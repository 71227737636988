
import React, { useEffect, useState } from 'react';
import { SideNavPhone, TopNav } from '../component/SideNav';
import { AleartMessage } from '../component/Aleart';
import { useNavigate } from 'react-router-dom';
import { validateTokenPay } from '../auth/authentication';
import { DownloadInvoices, PaymentUtils } from './paymentUtils';
import { TableForAll } from '../component/Table';
import InfinityLoader from '../component/COMP/loadingPage';


const Gateway = () => {
    const [amount, setAmount] = useState(0.00);
    const [serviceCharge, setServiceCharge] = useState(0.00);
    const [TotalAmount, setTotalAmount] = useState(0.00);
    const [details, setDetails] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentbalance, setCurrentBalance] = useState();
    const [enabled, setEnabled] = useState(false);
    const navigate = useNavigate();
    const [access, setAccess] = useState([]);
    const token = localStorage.getItem('token');


    useEffect(() => {
        const validateTokenAndFetch = async () => {
            const response = await validateTokenPay(navigate, token, "PHONE");
            try {
                if (!response.access.includes("PHONEPAY")) {
                    window.location.href = '/phone-manager';
                    return;
                }
                setAccess(response.access);
                setDetails(response.company);
                setPaymentDetails(response.payments);
                handleBalance(response.company.credits);
                setEnabled(true);
            } catch (err) {
                window.location.href = '/dashboard'
            }
        };

        validateTokenAndFetch();
    }, [navigate, token]);

    const handleBalance = (balance) => {
        const formattedCredits = parseFloat(balance).toFixed(4);
        setCurrentBalance(formattedCredits);
        localStorage.setItem("balance", formattedCredits);
    }

    const handleAmount = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        let parsedDouble = parseFloat(numericValue);
        if (!isNaN(parsedDouble)) {
            parsedDouble = parseFloat(parsedDouble.toFixed(2));
            setAmount(parsedDouble);
            const serviceCharge = (parsedDouble * 0.030).toFixed(2);
            setServiceCharge(parseFloat(serviceCharge));

            const totalAmount = (parsedDouble + parseFloat(serviceCharge)).toFixed(2);
            setTotalAmount(parseFloat(totalAmount));
        } else {
            clearAmount();
        }
    }

    const handlePaymentRequest = async () => {
        if (amount < 20)
            AleartMessage({ icon: 'warning', title: 'Check the Amount', text: 'Enter an amount between $20 and $2,000' })
        else {
            try {
                PaymentUtils({ token: token, totalAmount: TotalAmount, amount: amount })
            } catch (error) {
                console.error('Error processing payment:', error);
            }
        }
    }

    const columList = ["Order_Id", "Date & Time", "Status", "Amount", "Invoice"];

    const clearAmount = () => {
        setAmount(0.00);
        setServiceCharge(0.00);
        setTotalAmount(0.00);
    }

    window.onerror = function (message, source, lineno, colno, error) {
        if (message.includes("Failed to execute 'removeChild' on 'Node'")) {
            window.location.reload();
        }
        return false;
    };

    const DownloadInvoice = async (invoiceId) => {
        await DownloadInvoices(token, invoiceId);
    }

    return (
        <div>
            {enabled ? (
                <div>
                    <SideNavPhone item={3} access={access} />
                    <div className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200" id="panel">
                        <TopNav companyName={details.name} balance={currentbalance} />
                        <div className={`z-0 flex flex-wrap -mx-3 mb-5 removable`}>
                            <div className="w-8/12 max-w-8/12 px-3 ml-2 lg:w-8/12 sm:flex-none xl:mb-0 drop-zone">
                                <div className="relative flex flex-col h-full min-w-0 p-4 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border">
                                    <div className="relative h-full overflow-hidden bg-cover rounded-xl bg-gradient-to-r from-purple-500 to-pink-500" >
                                        <span className="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-gray-900 to-slate-800 opacity-80"></span>
                                        <div className="relative z-10 flex flex-col flex-auto h-full p-4">
                                            <h3 className="pt-2 font-bold text-white">Add funds</h3>
                                            <p className="text-white mb-3">How much would you like to add to your account balance today?</p>
                                            <div>
                                                <label htmlFor="price" className="block text-base font-medium text-white leading-6 text-gray-900 font-bold">Payment amount</label>
                                                <div className="relative mt-2 rounded-md shadow-sm">
                                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                        <span className="text-gray-500 sm:text-base font-bold">$</span>
                                                    </div>
                                                    <input type="text"
                                                        name="price"
                                                        value={amount === 0 ? '' : amount}
                                                        id="price"
                                                        onChange={handleAmount}
                                                        className="block w-32 font-bold mb-2 rounded-md border-0 py-2 pl-9 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-base sm:leading-6"
                                                        placeholder="0.00" />
                                                </div>
                                            </div>
                                            <p className={`${(amount < 20 && amount !== 0) ? 'text-red-500' : 'text-white'} mb-3`}>
                                                {(amount < 20 && amount !== 0) && (
                                                    <i className="fas fa-warning ease-bounce text-sm group-hover:translate-x-1.25 ml-1 leading-normal transition-all duration-200 text-red-500"></i>
                                                )}
                                                <span className="ml-2">Enter an amount between $20 and $2,000</span>
                                            </p>
                                            <button
                                                type="button"
                                                onClick={handlePaymentRequest}
                                                className="mr-3 w-3/12 max-w-3/12 px-3 mb-2 -mt-3 lg:w-3/12 inline-block px-6 py-3 font-bold text-center bg-gradient-to-tl from-blue-600 to-cyan-400 uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white">
                                                PAY NOW
                                            </button>
                                            <p className="mt-auto mb-0 font-semibold leading-normal text-white group text-sm">
                                                <i className="fas fa-info ease-bounce text-sm group-hover:translate-x-1.25 ml-1 leading-normal transition-all duration-200 text-red-500"></i>
                                                <span className="ml-2">Please note: 3% service fee will be added to the total amount of your transaction.</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-4/12 max-w-4/12 px-3 -ml-9 lg:w-4/12 sm:flex-none xl:mb-0 drop-zone">
                                <div className="relative flex flex-col h-full min-w-0 p-4 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border">
                                    <div className="relative h-full overflow-hidden bg-cover rounded-xl bg-gradient-to-r from-cyan-500 to-blue-500" >
                                        <span className="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-gray-900 to-slate-800 opacity-80"></span>
                                        <div className="relative z-10 flex flex-col flex-auto h-full p-4">
                                            <h3 className="pt-2 font-bold text-white">Overview</h3>
                                            <div className="grid font-bold text-lg grid-cols-1 divide-y divide-blue-200">
                                                <div className="flex justify-between text-white mb-2 mt-2">
                                                    <span>Recharge Amount:</span>
                                                    <span className="text-right">$ {amount.toFixed(2)}</span>
                                                </div>
                                                <div className="flex justify-between text-white mb-2 mt-2">
                                                    <span>Service Charge:</span>
                                                    <span className="text-right">$ {serviceCharge.toFixed(2)}</span>
                                                </div>
                                                <div className="flex justify-between text-white mb-2 mt-2">
                                                    <span>Total Amount:</span>
                                                    <span className="text-right">$ {TotalAmount.toFixed(2)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full max-w-full px-3 mb-0 lg:w-full sm:flex-none xl:mb-0 drop-zone">
                                <div className="relative flex justify-center flex-col h-full min-w-0 p-4 break-words bg-white shadow-xl rounded-2xl bg-clip-border">
                                    <TableForAll dataList={paymentDetails} columnList={columList} currentPage={currentPage} setCurrentPage={setCurrentPage} invoice={DownloadInvoice} />
                                </div>
                            </div>
                        </div>
                        <div id="outer-container" className="container-fluid transiton-activatedg">
                            <div id="inside-container" className="container">
                                <div className="row row_clr">
                                    <div className="something"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : <div><InfinityLoader /></div>}
        </div>
    );
};

export default Gateway;