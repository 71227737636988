import React, { useEffect, useRef } from 'react';
import { DateRange, DefinedRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const DateRangePicker = ({ dateRange, setDateRange, showPicker, setShowPicker }) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, setShowPicker]);


  const handleInputClick = () => {
    setShowPicker(!showPicker);
  };

  const handleDateChange = (ranges) => {
    const today = new Date();
    let selection = {
      startDate: today,
      endDate: today,
      key: 'selection',
    }
    if (ranges && ranges['range1']) {
      selection = ranges['range1'];
    } else if (ranges && ranges['selection']) {
      selection = ranges['selection'];
    }
    setDateRange([selection]);
    setShowPicker(false);
  };


  return (
    <div className="rounded-lg drop-shadow-xl inline-block w-full" style={{ zIndex: showPicker ? 50 : 'auto', position: "relative" }}>
      <input
        type="text"
        value={`${dateRange[0]?.startDate?.toDateString()} - ${dateRange[0]?.endDate?.toDateString()}`}
        className="focus:shadow-soft-primary-outline font-bold dark:bg-gray-950  text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-semibold text-gray-900 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
        readOnly
        onClick={handleInputClick}
      />
      {showPicker && (
        <div ref={dropdownRef}  className="flex mt-2 rounded-lg drop-shadow-xl shadow-lg" style={{ zIndex: 50, position: "absolute", top: "100%" }}>
          <DefinedRange
            onChange={handleDateChange}
            ranges={[
              {
                label: 'Today',
                range: () => ({
                  startDate: new Date(),
                  endDate: new Date(),
                }),
              },
              {
                label: 'Yesterday',
                range: () => ({
                  startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                  endDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                }),
              },
              {
                label: 'This Week',
                range: () => ({
                  startDate: new Date(),
                  endDate: new Date(new Date().setDate(new Date().getDate() + 6)),
                }),
              },
              {
                label: 'Last Week',
                range: () => ({
                  startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
                  endDate: new Date(),
                }),
              },
              {
                label: 'This Month',
                range: () => ({
                  startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                  endDate: new Date(),
                }),
              },
              {
                label: 'Last Month',
                range: () => ({
                  startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
                  endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
                }),
              },
              {
                label: 'Days Leading Up to Today',
                range: () => ({
                  startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
                  endDate: new Date(),
                }),
              },
              {
                label: 'Days Starting Today',
                range: () => ({
                  startDate: new Date(),
                  endDate: new Date(new Date().setDate(new Date().getDate() + 7)),
                }),
              },
            ]}
            className='font-bold'
          />
          <DateRange
            ranges={dateRange}
            onChange={handleDateChange}
            months={3}
            direction="horizontal"
            className='font-bold'
          />
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
