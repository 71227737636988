import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';


const MySwal = withReactContent(Swal);


const AleartMessage = async ({ icon, title, text, alt = 2, location = '', deleteStorage = '' }) => {
    await MySwal.fire({
        icon: icon,
        title: title,
        text: text,
    });

    if (alt === 1) {
        localStorage.removeItem(deleteStorage);
        window.location.href = location;
    }
};

const AlertTimer = async ({title, html, location}) => {
    let timerInterval;
    Swal.fire({
        title: title,
        html: html,
        timer: 1000,
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading();
            const timer = Swal.getPopup().querySelector("b");
            timerInterval = setInterval(() => {
                timer.textContent = `${Swal.getTimerLeft()}`;
            }, 100);
        },
        willClose: () => {
            clearInterval(timerInterval);
        }
    }).then((result) => {
        window.location.href = location;
    });
}

export { AleartMessage, AlertTimer };