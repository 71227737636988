import React from "react";
import 'choices.js/public/assets/styles/choices.css';
import { Pagination } from "./Pagination";
import '../css/other.css'



const TableForAll = ({ currentPage, setCurrentPage, columnList = [], dataList = [], loading = false, itemsPerPage = 23, invoice }) => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dataList.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(dataList.length / itemsPerPage);
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    return (
        <div>
            <div>
                <table className="items-center justify-center w-full mb-0 align-top border-gray-200 text-slate-500">
                    <thead className={`${loading && "blur-sm"} align-bottom`}>
                        <tr>
                            {columnList.map((col, index) => (
                                <th key={index} className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-red-400 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">{col}</th>
                            ))}

                        </tr>
                    </thead>
                    <tbody>
                        {loading && (
                            <div className="mt-72 animation" id="container">
                                <svg viewBox="0 0 100 100">
                                    <defs>
                                        <filter id="shadow">
                                            <feDropShadow dx="0" dy="0" stdDeviation="1.5" floodColor="#fc6767" />
                                        </filter>
                                    </defs>
                                    <circle
                                        id="spinner"
                                        style={{
                                            fill: 'transparent',
                                            stroke: '#dd2476',
                                            strokeWidth: '4px',
                                            strokeLinecap: 'round',
                                            filter: 'url(#shadow)',
                                        }} cx="50" cy="50" r="45" />
                                </svg>
                            </div>
                        )}
                        {currentItems.map((item, index) => (
                            <tr className={`${loading ? "blur-sm" : "font-semibold"}`} key={index}>
                                {Object.entries(item).map(([key, value]) => (
                                    <td key={key} className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                        <div className="flex px-3">
                                            <div className="my-auto">
                                                <h6 className="mb-0 leading-normal text-sm">{value}</h6>
                                            </div>
                                        </div>
                                    </td>
                                ))}
                                <td>
                                    <span onClick={() => invoice(Object.values(item)[0])} className="py-2.2 cursor-pointer px-3.6 text-xs rounded-1.8 inline-block whitespace-nowrap text-center bg-pink-300 text-fuchsia-700 align-baseline font-bold uppercase leading-none">
                                        Download Invoice
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className={`${loading && "blur-sm"} py-2 flex justify-end`}>
                    <nav className="absolute left-0 ml-12 mt-2 block">
                        <ul className=" flex font-bold pl-0 rounded list-none flex-wrap">
                            Total Records: {dataList.length}
                        </ul>
                    </nav>
                    <nav className="block">
                        <ul className="flex pl-0 rounded list-none flex-wrap">
                            <li>
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-pink-500 bg-white text-pink-500"
                                >
                                    <i className="fas fa-chevron-left -ml-px" aria-hidden="true"></i>
                                </button>
                            </li>
                            {Pagination(totalPages, currentPage, handlePageChange)}
                            <li>
                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                    className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-pink-500 bg-white text-pink-500"
                                >
                                    <i className="fas fa-chevron-right -mr-px" aria-hidden="true"></i>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};


const TableUser = ({ currentPage, setCurrentPage, columnList = [], dataList = [], loading = false, itemsPerPage = 23, handleEdit }) => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dataList.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(dataList.length / itemsPerPage);
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    return (
        <div>
            <div>
                <table className="items-center justify-center w-full mb-0 align-top border-gray-200 text-slate-500">
                    <thead className={`${loading && "blur-sm"} align-bottom`}>
                        <tr>
                            {columnList.map((col, index) => (
                                <th key={index} className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-red-400 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">{col}</th>
                            ))}

                        </tr>
                    </thead>
                    <tbody>
                        {loading && (
                            <div className="mt-72 animation" id="container">
                                <svg viewBox="0 0 100 100">
                                    <defs>
                                        <filter id="shadow">
                                            <feDropShadow dx="0" dy="0" stdDeviation="1.5" floodColor="#fc6767" />
                                        </filter>
                                    </defs>
                                    <circle
                                        id="spinner"
                                        style={{
                                            fill: 'transparent',
                                            stroke: '#dd2476',
                                            strokeWidth: '4px',
                                            strokeLinecap: 'round',
                                            filter: 'url(#shadow)',
                                        }} cx="50" cy="50" r="45" />
                                </svg>
                            </div>
                        )}
                        {currentItems.map((item, index) => (
                            <tr className={`${loading ? "blur-sm" : "font-semibold"}`} key={index}>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex px-3">
                                        <div className="my-auto">
                                            <h6 className="mb-0 leading-normal text-sm animate-pop-in">{item.firstName + " " + item.lastName}</h6>
                                        </div>
                                    </div>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex px-3">
                                        <div className="my-auto">
                                            <h6 className="mb-0 leading-normal text-sm animate-pop-in">{item.email}</h6>
                                        </div>
                                    </div>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex px-3">
                                        <div className="my-auto">
                                            <span className={`inline-flex px-2 text-xs font-semibold leading-5 ${item.status ? "text-green-800 bg-green-100" : "text-red-800 bg-red-100"} rounded-full animate-pop-in`}>{item.status ? "Online" : "Offline"}</span>
                                            <p className="mb-0 leading-normal text-sm"></p>
                                        </div>
                                    </div>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex px-3">
                                        <div className="my-auto">
                                            <span className={`inline-flex px-2 text-xs font-semibold leading-5 ${item.callRecord ? "text-green-800 bg-green-100" : "text-red-800 bg-red-100"} rounded-full animate-pop-in`}>{item.callRecord ? "Enabled" : "Disabled"}</span>
                                            <p className="mb-0 leading-normal text-sm"></p>
                                        </div>
                                    </div>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex px-3">
                                        <div className="my-auto">
                                            <h6 className="mb-0 leading-normal text-sm rounded-full animate-pop-in">{item.employed}</h6>
                                        </div>
                                    </div>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex px-3">
                                        <div className="my-auto">
                                            <button onClick={() => { handleEdit(item) }} className="text-indigo-600 hover:text-indigo-900 transition duration-300 ease-in-out hover:scale-105 animate-pop-in">Edit</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className={`${loading && "blur-sm"} py-2 flex justify-end`}>
                    <nav className="absolute left-0 ml-12 mt-2 block">
                        <ul className=" flex font-bold pl-0 rounded list-none flex-wrap">
                            Total Records: {dataList.length}
                        </ul>
                    </nav>
                    <nav className="block">
                        <ul className="flex pl-0 rounded list-none flex-wrap">
                            <li>
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-pink-500 bg-white text-pink-500"
                                >
                                    <i className="fas fa-chevron-left -ml-px" aria-hidden="true"></i>
                                </button>
                            </li>
                            {Pagination(totalPages, currentPage, handlePageChange)}
                            <li>
                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                    className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-pink-500 bg-white text-pink-500"
                                >
                                    <i className="fas fa-chevron-right -mr-px" aria-hidden="true"></i>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export { TableForAll, TableUser };