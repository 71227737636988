import React, { useEffect, useState } from 'react';
import '../Dashboard_Pages/assets/css/tailwind/tailwind.min.css';
import '../Dashboard_Pages/assets/css/theme.css';
import '../Dashboard_Pages/assets/css/loopple/loopple.css';
import { SideNavPhone, TopNav } from '../component/SideNav';
import { useNavigate } from 'react-router-dom';
import { PhoneCallHisListTable } from '../component/Phone_Comp/phoneList';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateRangePicker from '../component/DatePicker';
import {DropDown} from '../component/DropDown';
import { validateTokenNew } from '../auth/authentication';
import * as XLSX from 'xlsx';
import { GetAllUsers, GetCallLogs } from '../api/phone';
import { format } from 'date-fns';
import InfinityLoader from '../component/COMP/loadingPage';

const CallLogs = () => {
    const [details, setDetails] = useState([]);
    const [enabled, setEnabled] = useState(false);
    const [currentbalance, setCurrentBalance] = useState();
    const [showPicker, setShowPicker] = useState(false);
    const [searchUser, setSearchUser] = useState('');
    const [searchStatus, setSearchStatus] = useState('');
    const [userList, setUserList] = useState([]);
    const [user, setUser] = useState();
    const [status, setStatus] = useState();
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState();
    const [filterData, setFilterData] = useState();
    const [access, setAccess] = useState([]);

    const statuslist = [
        { id: "busy", name: "Busy" },
        { id: "canceled", name: "Canceled" },
        { id: "completed", name: "Completed" },
        { id: "failed", name: "Failed" },
        { id: "no-answer", name: "No Answer" },
    ];

    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);

    const navigate = useNavigate();

    const token = localStorage.getItem('token');

    useEffect(() => {
        const validateTokenAndFetch = async () => {
            const response = await validateTokenNew(navigate, token, "PHONE");
            try {
                if (!response.access.includes("CALL_LOGS")) {
                    window.location.href = '/phone-manager';
                    return;
                }
                setDetails(response.company);
                handleBalance(response.company.credits);
                setAccess(response.access)
                const users = localStorage.getItem('users');
                getUsers(users);
                setEnabled(true);
                handleSubmit();
                return;
            } catch (err) {
                window.location.href = '/dashboard'
            }
        };

        validateTokenAndFetch();
    }, [navigate, token]);

    const getUsers = async (users) => {
        if (users !== null) {
            setUserList(JSON.parse(users));
            setEnabled(true);
            return;
        }

        const response = await GetAllUsers(token);
        localStorage.setItem("users", JSON.stringify(response));
        try {
            setUserList(response);
        } catch (err) {
            window.location.href = '/dashboard'
        }
    };

    const handleBalance = (balance) => {
        const formattedCredits = parseFloat(balance).toFixed(4);
        setCurrentBalance(formattedCredits);
        localStorage.setItem("balance", formattedCredits);
    }

    const filteredUsers = userList.filter(user =>
        user['name'].toLowerCase().includes(searchUser.toLowerCase())
    );

    const filteredStatus = statuslist.filter(status =>
        status['name'].toLowerCase().includes(searchStatus.toLowerCase())
    );


    const handleOptionClickUser = option => {
        setUser(option);
    };

    const handleOptionClickStatus = option => {
        setStatus(option);
    };

    const handleClearButon = () => {
        setUser();
        setStatus();
        setSearchQuery();
        setDateRange([{
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }])
        setDataList([]);
        setFilterData([]);
        setCurrentPage(1);
    }

    const handleSubmit = async () => {
        setDataList([]);
        setLoading(true);
        const response = await GetCallLogs(dateRange, user, status, token);
        setDataList(response);
        setFilterData(response);
        setLoading(false);
        setCurrentPage(1);
        return;
    }

    const handleSearchChange = (e) => {
        const newSearchQuery = e.target.value;
        setSearchQuery(newSearchQuery);

        if (dataList.length > 0) {
            if (newSearchQuery === undefined || newSearchQuery.length === 0) {
                setFilterData(dataList);
            } else {
                let filteredData = dataList.filter((item) => {
                    return Object.values(item).some((value) => {
                        if (typeof value === 'string' && value.toLowerCase().includes(newSearchQuery.toLowerCase())) {
                            return true;
                        }
                        return false;
                    });
                });
                setFilterData(filteredData);
            }
        }
    }

    const downloadAsExcel = () => {
        const data = dataList;
        const headers = [
            "id", "userName", "date", "startTime", "endTime", "from", "to", "status", "duration", "price"
        ];
        const dataWithId = data.map((item, index) => ({ ...item, id: index + 1 }));
        const wsData = [headers, ...dataWithId.map(item => headers.map(key => item[key]))];

        const ws = XLSX.utils.aoa_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const currentDate = format(new Date(), 'yyyy-MM-dd');
        const fileName = `${currentDate}_Data.xlsx`;

        XLSX.writeFile(wb, fileName);
    };




    return (
        <div>
            {enabled ? (
                <div>
                    <SideNavPhone item={2} access={access} />
                    <div className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200" id="panel">
                        <TopNav companyName={details.name} balance={currentbalance} />
                        <div className="flex flex-wrap -mx-3 mb-5 removable">
                            <div className="w-full max-w-full px-3 mb-6 lg:w-full sm:flex-none xl:mb-0 drop-zone">
                                <div className="w-full px-6 py-6 mx-auto loopple-min-height-78vh text-slate-500">
                                    <div className="shadow-xl dop-shadow-lg flex flex-col min-w-0 mb-6 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border" style={{ zIndex: 10, position: "relative" }}>
                                        <div className="flex-auto p-4">
                                            <div className="flex flex-wrap -mx-3">
                                                <div className="flex-none w-2/12 max-w-full px-3">
                                                    <label className="block">
                                                        <span className="block z-100 text-sm ml-3 font-bold text-slate-900">Select Date</span>
                                                        <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} setShowPicker={setShowPicker} showPicker={showPicker} />
                                                    </label>
                                                </div>
                                                <div className="relative w-1.8/12 inline-block text-left">
                                                    <DropDown colour={"from-purple-700 to-pink-500"} value={user} title={"Select User"} searchTerm={searchUser} setSearchTerm={setSearchUser} handleOptionClick={handleOptionClickUser} filteredOptions={filteredUsers} />
                                                </div>
                                                <div className="relative w-1.5/12 ml-3 inline-block text-left">
                                                    <DropDown colour={"from-lime-700 to-green-500"} value={status} title={"Select Status"} searchTerm={searchStatus} setSearchTerm={setSearchStatus} handleOptionClick={handleOptionClickStatus} filteredOptions={filteredStatus} />
                                                </div>
                                                <div className="relative w-1.5/12 ml-3 inline-block text-left">
                                                    <button type="button"
                                                        onClick={handleSubmit}
                                                        disabled={loading}
                                                        className={`inline-flex mt-4 w-full mr-3 inline-block px-4 py-2.7 font-bold text-center bg-gradient-to-tl from-red-700 to-pink-600 uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white`} >
                                                        Apply Filter
                                                    </button>
                                                </div>
                                                <div className="relative w-1.5/12 ml-3 inline-block text-left">
                                                    <button type="button"
                                                        onClick={handleClearButon}
                                                        className={`inline-flex mt-4 w-full mr-3 inline-block px-4 py-2.7 font-bold text-center bg-gradient-to-tl from-blue-600 to-cyan-400 uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white`} >
                                                        Clear Filter
                                                    </button>
                                                </div>
                                                <div className="absolute mt-4 w-1.5/12 right-4 ml-3 inline-block text-left">
                                                    <button type="button"
                                                        onClick={downloadAsExcel}
                                                        className={`inline-flex w-full inline-block px-4 py-2.7 font-bold text-center bg-gradient-to-tl from-green-700 to-lime-500 uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white`} >
                                                        Download to file
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="shadow-xl dop-shadow-lg  flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border mb-4">
                                        <div className={`${loading ? "blur-sm" : ""} p-6 pb-0 mb-0 bg-white rounded-t-2xl flex justify-between`}>
                                            <h6>
                                                <div className={"relative flex flex-wrap items-stretch w-80 transition-all rounded-lg ease-soft"}>
                                                    <span className="text-sm ease-soft leading-5 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none  bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                                                        {!showPicker && (<i className="z-0 fas fa-search" aria-hidden="true"></i>)}
                                                    </span>
                                                    <input type="text"
                                                        className="pl-9 text-sm focus:shadow-soft-primary-outline ease-soft w-30 leading-5 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none focus:transition-shadow"
                                                        placeholder="Type here..."
                                                        value={searchQuery}
                                                        disabled={loading}
                                                        onChange={handleSearchChange}
                                                    />
                                                </div>
                                            </h6>
                                        </div>
                                        <div className="flex-auto px-0 pt-0 pb-2">
                                            <div className="p-0 overflow-x-auto">
                                                <PhoneCallHisListTable dataList={filterData} loading={loading} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : <div><InfinityLoader /></div> }
        </div>
    );
};

export default CallLogs;
