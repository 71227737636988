import React, { useEffect, useRef, useState } from "react";


const DropDown = ({ value, searchTerm, setSearchTerm, handleOptionClick, filteredOptions, title, colour }) => {
    const dropdownRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [dropdownRef, setIsOpen]);

      const name = filteredOptions.find(option => option.id === value);
    
    return (
        <label className="block">
            <span className="block text-sm ml-3 font-bold text-slate-900">{title}</span>
            <div>
                <button onClick={() => setIsOpen(!isOpen)} type="button"
                    className={`inline-flex w-full mr-3 inline-block px-4 py-2.7 font-bold text-center bg-gradient-to-tl ${colour} uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white`}
                    id="options-menu" aria-haspopup="true" aria-expanded="true">
                    {value !== undefined ? name['name'] : "Select options"}
                    <i className={`fas fa-chevron-down ml-2 ${isOpen ? 'transform rotate-180' : ''}`}></i>
                </button>

            </div>

            {isOpen && (
                <div ref={dropdownRef} className="origin-top-right absolute  mt-2 w-56 drop-shadow-xl rounded-md shadow-xl  bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1">
                        <div className="relative text-gray-600 focus-within:text-gray-400">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                <i className="fas fa-search"></i>
                            </span>
                            <input type="text" className="py-2 border-none text-sm leading-5 w-full focus:outline-none pl-10" placeholder="Search..." value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
                        </div>
                    </div>
                    <div className="max-h-60 overflow-y-auto" style={{ maxHeight: '15rem' }} >
                        {filteredOptions.map(option => (
                            <div key={option['id']} onClick={() => handleOptionClick(option['id'])} className="cursor-pointer px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-indigo-100 hover:text-gray-900 focus:outline-none focus:bg-indigo-100">
                                {option['name']}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </label>
    );
};



const DropDownNoSearch = ({ value, handleOptionClick, filteredOptions, colour }) => {
    const dropdownRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [dropdownRef, setIsOpen]);

    const name = filteredOptions.find(option => option.userId === value);
    
    return (
        <label className="block">
            <div>
                <button onClick={() => setIsOpen(!isOpen)} type="button"
                    className={`inline-flex w-full mr-3 inline-block px-4 py-2.7 font-bold text-center bg-gradient-to-tl ${colour} uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white`}
                    id="options-menu" aria-haspopup="true" aria-expanded="true">
                    {value !== undefined ? name['userFirstName'] + " " + name['userLastName'] : "Select options"}
                    <i className={`fas fa-chevron-down ml-2 ${isOpen ? 'transform rotate-180' : ''}`}></i>
                </button>

            </div>

            {isOpen && (
                <div ref={dropdownRef} className="origin-top-right absolute  mt-2 w-56 drop-shadow-xl rounded-md shadow-xl  bg-white ring-1 ring-black ring-opacity-5">
                    <div className="max-h-60 overflow-y-auto" style={{ maxHeight: '15rem' }} >
                        {filteredOptions.map(option => (
                            <div key={option['userId']} onClick={() => handleOptionClick(option['userId'])} className="cursor-pointer px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-indigo-100 hover:text-gray-900 focus:outline-none focus:bg-indigo-100">
                                {option['userFirstName'] + " " + option['userLastName']}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </label>
    );
};

export {DropDown, DropDownNoSearch};