import axios from "axios";
import { API_BASE_URL } from "../component/constants";

export const SaveLogDetails = async (SID, token) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/v1/phone?sid=${SID}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data.body;

    } catch (error) {
        console.error('Error sending POST request:', error);
    }
};

export const GetAllUsers = async (token) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/v1/phone/filter-details`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data.body;

    } catch (error) {
        console.error('Error sending POST request:', error);
    }
};


export const GetCallLogs = async (dates, user, status, token) => {
    const { startDate, endDate } = dates[0];
    const formattedSDate = convertDate(startDate);
    const formattedEDate = convertDate(endDate);
    try {
        const response = await axios.get(`${API_BASE_URL}/api/v1/phone?startDate=${formattedSDate}&endDate=${formattedEDate}&userId=${user === undefined ? 0 : user}&status=${status === undefined ? "" : status}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data.body;

    } catch (error) {
        console.error('Error sending POST request:', error);
    }
};


const convertDate = (inputDateString) => {
    const dateObject = new Date(inputDateString);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}

export const GetAllUsersByRecoringDate = async (token) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/v1/phone/filter-details`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data.body;

    } catch (error) {
        console.error('Error sending POST request:', error);
    }
};


export const HandleDownload = async (folderName, token) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/v1/phone/download-recording`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                folder: folderName
            },
            responseType: 'blob'
        });

        console.log("Response received, blob size: ", response.data.size);

        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/x-rar-compressed' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'folder.rar');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        console.log("Download initiated");
    } catch (error) {
        console.log('Failed to download folder: ' + error.message);
    }
};
