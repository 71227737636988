import React, { useEffect } from 'react';
import Swal from 'sweetalert2';

const endpoint = "https://app.zenithwavesolutions.com/phone-manager";

export const Success = () => {
    useEffect(() => { // Use useEffect to call alert when the component mounts
        let timerInterval;
        Swal.fire({
            title: "Payment success",
            html: "Please wait you will be redirected in <b></b> milliseconds.",
            timer: 3000,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
                const b = Swal.getPopup().querySelector('b');
                timerInterval = setInterval(() => {
                    b.textContent = Swal.getTimerLeft();
                }, 100);
            },
            willClose: () => {
                clearInterval(timerInterval);
            }
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
                window.location.href = endpoint;
            }
        });
    }, []); // Empty dependency array means this effect runs once on mount

    return (
        <div id="outer-container" className="container-fluid transition-activated">
            <div id="inside-container" className="container">
                <div className="row row_clr">
                    {/* Alert function is now called automatically when component mounts */}
                </div>
            </div>
        </div>
    );
};


export const Failed = () => {
    useEffect(() => { // Use useEffect to call alert when the component mounts
        let timerInterval;
        Swal.fire({
            title: "Payment failed",
            html: "Please try again in <b></b> milliseconds.",
            timer: 3000,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
                const timer = Swal.getPopup().querySelector("b");
                timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                }, 100);
            },
            willClose: () => {
                clearInterval(timerInterval);
            }
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
                window.location.href = endpoint
            }
        });
    }, []); // Empty dependency array means this effect runs once on mount

    return (
        <div id="outer-container" className="container-fluid transition-activated">
            <div id="inside-container" className="container">
                <div className="row row_clr">
                    {/* Alert function is now called automatically when component mounts */}
                </div>
            </div>
        </div>
    );
};
