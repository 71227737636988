import React, { useEffect, useState } from 'react';
import './assets/css/tailwind/tailwind.min.css';
import './assets/css/theme.css';
import './assets/css/loopple/loopple.css';
import { SideNav, TopNav } from '../component/SideNav';
import '../css/other.css';
import { useNavigate } from 'react-router-dom';
import { validateTokenNew } from '../auth/authentication';
import { GetPhoneNumbers, GetTemplates } from '../3rdParty/whatsapp';
import { TemplateTable } from '../component/TemplateTable';
import InfinityLoader from '../component/COMP/loadingPage';

const TemplateManager = () => {
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [phoneNumberDetails, setphoneNumberDetails] = useState([]);
    const [currentBusiness, setCurrentBusiness] = useState();
    const [templates, setTemplates] = useState([]);
    const [enabled, setEnabled] = useState(false);


    useEffect(() => {
        const fetchBusiness = async () => {
            try {
                await validateTokenNew(navigate, token, "WHATSAPP");
                setEnabled(true);
                const storedDataString = localStorage.getItem('phoneNumberDetails');
                let storedDataList = [];
                if (storedDataString && storedDataString !== "undefined") {
                    storedDataList = JSON.parse(storedDataString);
                }

                if (storedDataList.length > 0) {
                    setphoneNumberDetails(storedDataList);
                } else {
                    const payload = await GetPhoneNumbers(token);
                    if (payload) {
                        setphoneNumberDetails(payload);
                        storedDataList = payload;
                        localStorage.setItem('phoneNumberDetails', JSON.stringify(payload));
                    }
                }
                const firstItem = storedDataList.length > 0 ? storedDataList[0] : null;
                if (firstItem != null) {
                    setCurrentBusiness(firstItem.business.businessId)
                    getAllTemplates(token, firstItem.business.businessId);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchBusiness();
    }, [navigate, token]);

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const closeDropdown = () => {
        setDropdownOpen(false);
    };


    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlePageReload = () => {
        localStorage.removeItem('templateList');
        window.location.reload();
    };

    const filteredData = searchQuery
        ? templates.filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()))
        : templates;

    const handleCreateProjectClick = () => {
        navigate('/template-manager');
        return;
    };

    const getAllTemplates = async (token, businessId) => {
        try {
            const templateDataList = JSON.parse(localStorage.getItem('templateList'));
            if (templateDataList !== null) {
                setTemplates(templateDataList);
                return;
            }
            setLoading(true);
            const templates = await GetTemplates(token, businessId);
            localStorage.setItem('templateList', JSON.stringify(templates));
            setTemplates(templates);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleBusinessClick = (value) => {
        setCurrentBusiness(value);
        getAllTemplates(token, value);
    }

    return (
        <div>
            {enabled && (
                <div>
                    <SideNav item={3} />
                    <div className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200" id="panel">
                        <TopNav companyName={"ZenithWave Solutions"} />
                        {loading ? <div><InfinityLoader /></div> : (
                            <div className="w-full px-6 py-6 mx-auto loopple-min-height-78vh text-slate-500">
                                <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border mb-4">
                                    <div className="absolute  mx-10 inline-block text-left" onBlur={closeDropdown} tabIndex="0">
                                        <div>
                                            <button
                                                type="button"
                                                className="inline-flex w-2/16 bg-gradient-to-tl hover:scale-102 from-red-300 to-yellow-500 gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                id="menu-button"
                                                aria-expanded={isDropdownOpen}
                                                aria-haspopup="true"
                                                onClick={toggleDropdown}
                                            >
                                                {currentBusiness != null ? (phoneNumberDetails.find((business) => business.business.businessId === currentBusiness)).business.name : "Select the Business Account"}
                                                <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                                </svg>
                                            </button>
                                        </div>
                                        {isDropdownOpen && (
                                            <div
                                                className="absolute z-100 mt-2 w-72 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                role="menu"
                                                aria-orientation="vertical"
                                                aria-labelledby="menu-button"
                                                tabIndex="-1"
                                            >
                                                <div className="py-1" role="none">
                                                    {phoneNumberDetails &&
                                                        phoneNumberDetails.map((businessDetails) => (
                                                            <p key={businessDetails.business.businessId} onMouseDown={() => handleBusinessClick(businessDetails.business.businessId)} className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100" role="menuitem" tabIndex="-1" id="menu-item-0">
                                                                {businessDetails.business.name}
                                                            </p>
                                                        ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border mb-4">
                                    <div className={`p-6 pb-0 mb-0 bg-white rounded-t-2xl flex justify-between`}>
                                        <h6>Tamplates
                                            <div className={"relative flex flex-wrap items-stretch w-80 transition-all rounded-lg ease-soft"}>
                                                <span className="text-sm ease-soft leading-5 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none  bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                                                    <i className="fas fa-search" aria-hidden="true"></i>
                                                </span>
                                                <input type="text"
                                                    className="pl-9 text-sm focus:shadow-soft-primary-outline ease-soft w-30 leading-5 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none focus:transition-shadow"
                                                    placeholder="Type here..."
                                                    value={searchQuery}
                                                    onChange={handleSearchChange}
                                                />
                                            </div>
                                        </h6>
                                        <div className='h-full'>
                                            <button type="button" onClick={handlePageReload}
                                                className="mr-3 inline-block px-6 py-3 font-bold text-center bg-gradient-to-tl from-blue-700 to-green-500 uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white">
                                                <i className="fa fa-refresh"></i>
                                            </button>
                                            <button type="button"
                                                onClick={handleCreateProjectClick}
                                                className="mr-3 inline-block px-6 py-3 font-bold text-center bg-gradient-to-tl from-purple-700 to-pink-500 uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white">
                                                Create Template
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex-auto px-0 pt-0 pb-2">
                                        <div className="p-0 overflow-x-auto">
                                            {filteredData.length <= 0 ? <div className={`relative w-full p-2 text-white bg-yellow-500 rounded-lg`}>No records were found</div>
                                                : <TemplateTable dataList={filteredData} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TemplateManager;
