import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { nanoid } from 'nanoid';

const TemplateDropdown = ({ elements, setElements }) => {

    const handleAddElement = (value) => {
        if (value === 'Website') {
            const newObject = {
                id: nanoid(),
                type: 'URL',
                text: "",
                value: ""
            };
            addObjectToList(newObject, "URL");
        } else if (value === 'Phone Number') {
            const newObject = {
                id: nanoid(),
                type: 'PHONE_NUMBER',
                text: "",
                value: ""
            };
            addObjectToList(newObject, "PHONE_NUMBER");
        } else if (value === 'Quick Reply') {
            const newObject = {
                id: nanoid(),
                type: 'QUICK_REPLY',
                text: "",
                value: ""
            };
            addObjectToList(newObject, "QUICK_REPLY");
        }
    };

    const addObjectToList = (newObject, type) => {
        if (elements.length <= 2) {
            const typeCount = elements.filter((element) => element.type === type).length;
            if (type === 'URL' && typeCount <= 1) {
                setElements((prevElements) => [...prevElements, newObject]);
            } else if (type === 'PHONE_NUMBER' && typeCount <= 0) {
                setElements((prevElements) => [...prevElements, newObject]);
            } else if (type === 'QUICK_REPLY' && typeCount <= 2) {
                setElements((prevElements) => [...prevElements, newObject]);
            }
        }
        return;
    };


    const handleElementChange = (e) => {
        const { name, title, value } = e.target;

        setElements((prevElements) =>
            prevElements.map((element) =>
                element.id === name
                    ? title === "title" ? { ...element, text: value } : { ...element, value: value }
                    : element
            )
        );
    };

    const deleteElements = (id) => {
        setElements((prevElements) => prevElements.filter((element) => element.id !== id));
    }

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div>
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button
                        className="inline-block ml-10 w-full px-6 py-2.5 mb-2 font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-gradient-to-tl from-purple-700 to-pink-500 leading-pro text-sm ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs"
                    >
                        + Add Buttons
                    </Menu.Button>
                </div>
                <Transition
                    as={React.Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 z-10 mt-1 w-56 origin-top-right rounded-lg bg-white shadow-2xl drop-shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={() => {
                                            handleAddElement('Website');
                                        }}
                                        className={classNames(
                                            active ? 'bg-red-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm w-full'
                                        )}
                                    >
                                        Website
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={() => {
                                            handleAddElement('Phone Number');
                                        }}
                                        className={classNames(
                                            active ? 'bg-red-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm w-full'
                                        )}
                                    >
                                        Phone Number
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={() => {
                                            handleAddElement('Quick Reply');
                                        }}
                                        className={classNames(
                                            active ? 'bg-red-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm w-full'
                                        )}
                                    >
                                        Quick Reply
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>


            <div className={elements.length !== 0 && "mb-6 border border-gray-300 rounded-lg shadow-md p-4"}>
                {elements.map((element) => (
                    ((element.type === 'URL') && (
                        <div className="flex mb-4">
                            <input
                                type="text"
                                disabled={true}
                                value={"URL"}
                                className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                style={{ marginRight: "10px" }}
                            />
                            <input
                                type="text"
                                required
                                placeholder="Button Title"
                                name={element.id}
                                value={element.text}
                                title='title'
                                maxLength={25}
                                onChange={(e) => handleElementChange(e)}
                                className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                style={{ marginRight: "10px" }}
                            />
                            <input
                                type="text"
                                required
                                placeholder="Button value"
                                name={element.id}
                                value={element.value}
                                title='text'
                                maxLength={2000}
                                onChange={(e) => handleElementChange(e)}
                                className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                style={{ marginLeft: "10px" }}
                            />
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded-lg ml-2"
                                onClick={() => deleteElements(element.id)}
                            >
                                Delete
                            </button>
                        </div>
                    )) ||
                    ((element.type === "PHONE_NUMBER") && (
                        <div className="flex mb-4">
                            {/* Phone Number element input fields */}
                            <input
                                type="text"
                                disabled={true}
                                value={"Phone Number"}
                                className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                style={{ marginRight: "10px" }}
                            />
                            <input
                                type="text"
                                placeholder="Button Title"
                                title='title'
                                name={element.id}
                                required
                                maxLength={25}
                                value={element.text}
                                onChange={(e) => handleElementChange(e)}
                                className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                style={{ marginRight: "10px" }}
                            />
                            <input
                                type="number"
                                placeholder="Button value"
                                title='text'
                                required
                                name={element.id}
                                maxLength={20}
                                value={element.value}
                                onChange={(e) => handleElementChange(e)}
                                className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                style={{ marginLeft: "10px" }}
                            />
                            <button
                                onClick={() => deleteElements(element.id)}
                                className="bg-red-500 text-white px-4 py-2 rounded-lg ml-2"
                            >
                                Delete
                            </button>
                        </div>
                    )) ||
                    ((element.type === "QUICK_REPLY") && (
                        <div className="flex mb-4">
                            <input
                                type="text"
                                placeholder="Quick Reply"
                                title='text'
                                name={element.id}
                                required
                                value={element.value}
                                maxLength={25}
                                onChange={(e) => handleElementChange(e)}
                                className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                style={{ marginLeft: "10px" }}
                            />
                            <button
                                onClick={() => deleteElements(element.id)}
                                className="bg-red-500 text-white px-4 py-2 rounded-lg ml-2"
                            >
                                Delete
                            </button>
                        </div>
                    ))
                ))}
            </div>

        </div>
    );
};

export default TemplateDropdown;
