import React, { useState, useEffect, useRef } from 'react';
import './assets/css/tailwind/tailwind.min.css';
import './assets/css/theme.css';
import './assets/css/loopple/loopple.css';
import '../css/other.css';
import { SideNav, TopNav } from '../component/SideNav';
import TemplateDropdown from '../component/TemplateDropdown';
import { BusinessProfiles, HeaderTemplate, handleSubmitButton } from '../component/CPPages';
import { useNavigate } from 'react-router-dom';
import { validateTokenNew } from '../auth/authentication';
import { AleartMessage } from '../component/Aleart';


const WhatsappTemplate = () => {
    const [header, setHeader] = useState("");
    const [formData, setFormData] = useState({
        language: "en_US",
        header: "",
        businessId: "",
    });

    const formRef = useRef();
    const [message, setMessage] = useState('');
    const [variables, setVariables] = useState([]);
    const [variableValues, setVariableValues] = useState([]);
    const [highestIndex, setHighestIndex] = useState(0);
    const [elements, setElements] = useState([]);
    const [headerValue, setHeaderValue] = useState('');
    const [footer, setFooter] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [radioButton, setRadioButton] = useState(0);
    const [fileDataUrl, setFileDataUrl] = useState(null);
    const [templateName, setTemplateName] = useState('');
    const [templateNameError, setTemplateNameError] = useState('');
    const [phoneNumberDetails, setphoneNumberDetails] = useState([]);
    const [templateType, setTemplateType] = useState();
    const [enabled, setEnabled] = useState(false);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const addVariable = () => {
        let newVariableIndex = highestIndex + 1;
        let bodyVariable = 1;

        if (variables.length !== 0) {
            const highestNumber = Math.max(...variables);
            bodyVariable = highestNumber + 1;
        }
        setMessage((prevMessage) => `${prevMessage}{{${bodyVariable}}} `);

        // Update state variables
        setVariables((prevVariables) => [...prevVariables, newVariableIndex]);
        setVariableValues((prevValues) => [...prevValues, '']);
        setHighestIndex(newVariableIndex);
    };

    const handleFooter = (value) => {
        setFooter(value);
    }

    const handleHeaderValue = (e) => {
        setHeaderValue(e);
    }

    const handleValueChange = (index, value) => {
        const updatedValues = [...variableValues];
        updatedValues[index] = value;
        setVariableValues(updatedValues);
    };

    useEffect(() => {
        const fetchData = async () => {
            console.log(1);
          await validateTokenNew(navigate, token, "WHATSAPP");
          setEnabled(true);
      
          const regex = /{{(\d+)}}/g;
          const matches = [...message.matchAll(regex)];
          let newHighestIndex = highestIndex;
      
          const uniqueVariables = matches.map((match, idx) => {
            const currentVariable = parseInt(match[1]);
            newHighestIndex = Math.max(newHighestIndex, currentVariable + 1);
            return idx + 1; // Always set a new index based on the order in the message
          });
      
          // Increment highestIndex if it is not the same as before
          if (newHighestIndex !== highestIndex) {
            setHighestIndex(newHighestIndex);
          }
      
          setVariables(uniqueVariables);
          setVariableValues(uniqueVariables.map((variable) => variableValues[variable - 1] || ''));
          setMessage(message);
        };
        fetchData();
      }, [message, navigate, highestIndex, token]);
      

    const handleTextareaChange = (e) => {
        const updatedMessage = e.target.value.replace(/{{\d+}}/g, (match) => {
            return match;
        });
        setMessage(updatedMessage);
        const regex = /{{\d+}}/g;
        const matches = [...updatedMessage.matchAll(regex)];
        const lengthDifference = matches.length - variables.length + 1;
        const paddedVariables = lengthDifference > 0 ? [...variables, ...Array(lengthDifference).fill(0)] : variables;

        const updatedMessageWithNumbers = updatedMessage.replace(regex, () => {
            const assignedNumber = paddedVariables.shift();
            return `{{${assignedNumber}}}`;
        });

        setMessage(updatedMessageWithNumbers);
    };

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        if (name === "header") {
            setHeader("");
            setHeaderValue("");
            setRadioButton(0);
        }
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === "textarea" || type === "text" ? value : e.target.value,
        }));
    };

    const handleHeader = (e) => {
        setHeader(e.target.value);
        !header.includes("{{1}}") && setHeaderValue("");
    }

    const addHeaderVariable = () => {
        setHeaderValue("");
        setHeader(header + " {{1}}")
    }

    const handleRadioChange = (value) => {
        setSelectedFile(null);
        setRadioButton(value);
    }

    const handleFileChange = (event, inputType) => {
        const file = event.target.files[0];

        // Reset previous values
        setSelectedFile(null);
        setErrorMessage('');

        if (file) {
            // Size validation
            const maxSize = inputType === 2 ? 20 * 1024 * 1024 : 2 * 1024 * 1024; // 20 MB for video, 2 MB for image and document
            if (file.size > maxSize) {
                setErrorMessage(`File size exceeds`);
                return;
            }
            switch (inputType) {
                case 1: // Image
                    const imageFormats = ['image/jpeg', 'image/png'];
                    if (imageFormats.includes(file.type)) {
                        setSelectedFile(file);
                    } else {
                        setErrorMessage('Invalid image format.');
                    }
                    break;

                case 2: // Video
                    const videoFormats = ['video/mp4'];
                    if (videoFormats.includes(file.type)) {
                        setSelectedFile(file);
                    } else {
                        setErrorMessage('Invalid video format.');
                    }
                    break;

                case 3: // Document
                    const documentFormats = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
                    if (documentFormats.includes(file.type)) {
                        setSelectedFile(file);
                    } else {
                        setErrorMessage('Invalid document format.');
                    }
                    break;

                default:
                    setErrorMessage('Invalid input type.');
                    break;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                setFileDataUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await handleSubmitButton(token, templateName, formData, header, headerValue, message, variableValues, footer, elements, radioButton, selectedFile, templateType);
        if (res.body) {
            await AleartMessage({ icon: 'success', title: 'Success!', text: 'Template created successfully!', alt: 1, location: '/template', deleteStorage: 'templateList' })
        } else {
            const jsonMatch = res.message.match(/{.*}/);
            if (jsonMatch) {
                const jsonString = jsonMatch[0];
                try {
                    const jsonObject = JSON.parse(jsonString);
                    const errorUserTitle = jsonObject.error && jsonObject.error.error_user_msg;

                    if (errorUserTitle) {
                        AleartMessage({ icon: 'error', title: 'Oops...', text: errorUserTitle, alt: 2 })
                    } else {
                        console.log('Error User Title not found in the JSON.');
                    }
                } catch (parseError) {
                    console.error('Error parsing JSON:', parseError.message);
                }
            }

        }
    }

    const handleTemplateName = (event) => {
        const enteredName = event.target.value;
        const lowercaseName = enteredName.toLowerCase().split(' ').join('_');
        const isValid = /^[0-9a-zA-Z_]*$/.test(lowercaseName);
        if (isValid) {
            setTemplateName(lowercaseName);
            setTemplateNameError('');
        } else {
            setTemplateNameError('Invalid element name. Template names can only contain text, numbers and underscores.');
        }
    }


    const handleTemplateType = (value) => {
        setTemplateType(value);
    }

    return (
        <div>
            {enabled && (
                <div>
                    <SideNav item={3} />
                    <div className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200" id="panel">
                        <TopNav companyName={"ZenithWave Solutions"} />
                        <div className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200 overflow-hidden" id="panel">
                            <div className="w-full px-6 py-6 mx-auto loopple-min-height-78vh text-slate-500">
                                <div className="flex flex-wrap -mx-3 mb-5 removable ">
                                    <div className="w-full max-w-full px-3 mb-6 lg:w-8/12 sm:flex-none xl:mb-0 h-screen overflow-auto">
                                        <div className="flex flex-wrap -mx-3 mb-5 removable">
                                            <form ref={formRef} onSubmit={handleSubmit} id='template' name='template' className="w-full max-w-full px-3 mb-6 sm:w-full sm:flex-none xl:mb-0 ">
                                                <div className="relative flex flex-col min-w-0 mb-2 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border drop-shadow-lg ">
                                                    <div className="flex-auto p-4">
                                                        <div className="flex flex-wrap -mx-3">
                                                            <div className="flex-none w-2/3 max-w-full px-3">
                                                                <div>
                                                                    <h5>Template name</h5>
                                                                    <h6 className="text-slate-500">Give your message template a name.</h6>
                                                                    <input required onChange={handleTemplateName} value={templateName} type="text" placeholder="Template Name"
                                                                        className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none" />
                                                                    {templateNameError && <p className="text-xs indent-2 text-red-500">{templateNameError}</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="relative flex flex-col min-w-0 mb-2 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border drop-shadow-lg ">
                                                    <div className="flex-auto p-4">
                                                        <div className="flex flex-wrap -mx-3">
                                                            <div className="flex-none w-2/3 max-w-full px-3">
                                                                <div>
                                                                    <h5>Category</h5>
                                                                    <h6 className="text-slate-500">Choose what type of message template you want
                                                                        to create.</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ul className="grid w-full gap-6 md:grid-cols-3">
                                                            <li>
                                                                <input onClick={() => handleTemplateType("MARKETING")} type="radio" id="react-option" value="" name="framework" className="hidden peer" />
                                                                <label htmlFor="react-option"
                                                                    className="inline-flex items-center selectable-label justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                                                    <div className="block">
                                                                        <i className="fa fa-bullhorn mb-2 w-7 text-sky-500" aria-hidden="true"></i>
                                                                        <div className="w-full text-lg font-semibold">Marketing</div>
                                                                        <div className="w-full text-sm">Send promo offers, product offers and more to increase awareness and engagement.</div>
                                                                    </div>
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <input onClick={() => handleTemplateType("MARKETING")} type="radio" id="flowbite-option" name="framework" value="" className="hidden peer" />
                                                                <label htmlFor="flowbite-option"
                                                                    className="inline-flex items-center selectable-label justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                                                    <div className="block">
                                                                        <i className="fa fa-cogs mb-2 w-7 text-red-500" aria-hidden="true"></i>
                                                                        <div className="w-full text-lg font-semibold">Utility</div>
                                                                        <div className="w-full text-sm">Send account updates, order updates, alerts and more to share important information.</div>
                                                                    </div>
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <input onClick={() => handleTemplateType("MARKETING")} type="radio" id="angular-option" name="framework" value="" className="hidden peer" />
                                                                <label htmlFor="angular-option"
                                                                    className="inline-flex items-center selectable-label justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                                                    <div className="block">
                                                                        <i className="fa fa-unlock-alt mb-2 w-7 text-lime-500" aria-hidden="true"></i>
                                                                        <div className="w-full text-lg font-semibold">Authentication</div>
                                                                        <div className="w-full text-sm">Send codes that allow your customers to access their accounts.
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="relative flex flex-col min-w-0 mb-2 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border drop-shadow-lg ">
                                                    <div className="flex-auto p-4">
                                                        <div className="flex flex-wrap -mx-3">
                                                            <div className="flex-none w-3/3 max-w-full px-3">
                                                                <div>
                                                                    <h5>Language</h5>
                                                                    <h6 className="text-slate-1000">Choose which languages your message template
                                                                        will be sent in.</h6>
                                                                    <div className="w-4/5">
                                                                        <select
                                                                            name="language"
                                                                            value={formData.language}
                                                                            onChange={handleInputChange}
                                                                            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-2 mr-4`}
                                                                        >
                                                                            <option value="af">Afrikaans</option>
                                                                            <option value="sq">Albanian</option>
                                                                            <option value="ar">Arabic</option>
                                                                            <option value="az">Azerbaijani</option>
                                                                            <option value="bn">Bengali</option>
                                                                            <option value="bg">Bulgarian</option>
                                                                            <option value="ca">Catalan</option>
                                                                            <option value="zh_CN">Chinese (CHN)</option>
                                                                            <option value="zh_HK">Chinese (HKG)</option>
                                                                            <option value="zh_TW">Chinese (TAI)</option>
                                                                            <option value="hr">Croatian</option>
                                                                            <option value="cs">Czech</option>
                                                                            <option value="da">Danish</option>
                                                                            <option value="nl">Dutch</option>
                                                                            <option value="en">English</option>
                                                                            <option value="en_GB">English (UK)</option>
                                                                            <option value="en_US">English (US)</option>
                                                                            <option value="et">Estonian</option>
                                                                            <option value="fil">Filipino</option>
                                                                            <option value="fi">Finnish</option>
                                                                            <option value="fr">French</option>
                                                                            <option value="ka">Georgian</option>
                                                                            <option value="de">German</option>
                                                                            <option value="el">Greek</option>
                                                                            <option value="gu">Gujarati</option>
                                                                            <option value="ha">Hausa</option>
                                                                            <option value="he">Hebrew</option>
                                                                            <option value="hi">Hindi</option>
                                                                            <option value="hu">Hungarian</option>
                                                                            <option value="id">Indonesian</option>
                                                                            <option value="ga">Irish</option>
                                                                            <option value="it">Italian</option>
                                                                            <option value="ja">Japanese</option>
                                                                            <option value="kn">Kannada</option>
                                                                            <option value="kk">Kazakh</option>
                                                                            <option value="rw_RW">Kinyarwanda</option>
                                                                            <option value="ko">Korean</option>
                                                                            <option value="ky_KG">Kyrgyz (Kyrgyzstan)</option>
                                                                            <option value="lo">Lao</option>
                                                                            <option value="lv">Latvian</option>
                                                                            <option value="lt">Lithuanian</option>
                                                                            <option value="mk">Macedonian</option>
                                                                            <option value="ms">Malay</option>
                                                                            <option value="ml">Malayalam</option>
                                                                            <option value="mr">Marathi</option>
                                                                            <option value="nb">Norwegian</option>
                                                                            <option value="fa">Persian</option>
                                                                            <option value="pl">Polish</option>
                                                                            <option value="pt_BR">Portuguese (BR)</option>
                                                                            <option value="pt_PT">Portuguese (POR)</option>
                                                                            <option value="pa">Punjabi</option>
                                                                            <option value="ro">Romanian</option>
                                                                            <option value="ru">Russian</option>
                                                                            <option value="sr">Serbian</option>
                                                                            <option value="sk">Slovak</option>
                                                                            <option value="sl">Slovenian</option>
                                                                            <option value="es">Spanish</option>
                                                                            <option value="es_AR">Spanish (ARG)</option>
                                                                            <option value="es_ES">Spanish (SPA)</option>
                                                                            <option value="es_MX">Spanish (MEX)</option>
                                                                            <option value="sw">Swahili</option>
                                                                            <option value="sv">Swedish</option>
                                                                            <option value="ta">Tamil</option>
                                                                            <option value="te">Telugu</option>
                                                                            <option value="th">Thai</option>
                                                                            <option value="tr">Turkish</option>
                                                                            <option value="uk">Ukrainian</option>
                                                                            <option value="ur">Urdu</option>
                                                                            <option value="uz">Uzbek</option>
                                                                            <option value="vi">Vietnamese</option>
                                                                            <option value="zu">Zulu</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <HeaderTemplate
                                                    formData={formData}
                                                    handleInputChange={handleInputChange}
                                                    handleRadioChange={handleRadioChange}
                                                    selectedFile={selectedFile}
                                                    errorMessage={errorMessage}
                                                    handleFileChange={handleFileChange}
                                                    handleHeader={handleHeader}
                                                    header={header}
                                                    addHeaderVariable={addHeaderVariable}
                                                    handleHeaderValue={handleHeaderValue}
                                                    radioButton={radioButton}
                                                />
                                                <div className="relative flex flex-col min-w-0 mb-2 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border drop-shadow-lg">
                                                    <div className="flex-auto p-4">
                                                        <div className="flex flex-wrap -mx-5">
                                                            <div className="flex-none w-full max-w-full px-3">
                                                                <div>
                                                                    <h5>Body</h5>
                                                                    <h6 className="text-slate-700">
                                                                        Enter the text for your message in the language you have selected.
                                                                    </h6>
                                                                    <div className="relative">
                                                                        <textarea
                                                                            name="textarea-name"
                                                                            rows="4"
                                                                            required
                                                                            placeholder="Enter Message"
                                                                            maxLength="1024"
                                                                            value={message}
                                                                            onChange={handleTextareaChange}
                                                                            className="focus:shadow-soft-primary-outline min-h-unset text-sm leading-5.6 ease-soft block h-auto w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-1 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none resize-none"
                                                                        ></textarea>
                                                                        <div className="text-xs text-gray-500">
                                                                            <span className="absolute -mx-3 right-0 h-16 w-16">{message.length}/1024</span>
                                                                        </div>
                                                                        <br />
                                                                        <button
                                                                            onClick={addVariable}
                                                                            className="text-sm bg-transparent hover:bg-blue-100 text-blue-700 font-semibold py-1 px-2 border border-blue-500 hover:border-transparent rounded mb-2 ml-1"
                                                                        >
                                                                            + Add Variable
                                                                        </button>
                                                                        {variables.length !== 0 && (
                                                                            <div className="flex-none w-2/3 max-w-full px-3">
                                                                                <h6 className="text-slate-500">Define sample variable value for body</h6>
                                                                                {variables.map((variable, index) => (
                                                                                    <div className="flex mb-1" key={index}>
                                                                                        <input
                                                                                            type="text"
                                                                                            placeholder="Variable"
                                                                                            readOnly
                                                                                            value={`{{${variable}}}`}
                                                                                            className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                                                                            style={{ marginRight: '10px' }}
                                                                                        />
                                                                                        <input
                                                                                            type="text"
                                                                                            required
                                                                                            placeholder="Sample value"
                                                                                            value={variableValues[index]}
                                                                                            onChange={(e) => handleValueChange(index, e.target.value)}
                                                                                            className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                                                                            style={{ marginLeft: '10px' }}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="relative flex flex-col min-w-0 mb-2 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border drop-shadow-lg">
                                                    <div className="flex-auto p-4">
                                                        <div className="flex flex-wrap -mx-3">
                                                            <div className="flex-none w-full max-w-full px-3">
                                                                <div>
                                                                    <h5>Footer</h5>
                                                                    <h6 className="text-slate-500">Add a 60 character footer to your message.
                                                                        Variables are not supported in the footer.</h6>
                                                                    <input type="text" placeholder="Footer Text"
                                                                        onChange={(e) => handleFooter(e.target.value)}
                                                                        value={footer}
                                                                        className="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none" />
                                                                    <div className="bottom-1 right-4 text-xs text-gray-500">
                                                                        <span className='absolute -mx-1 right-0 h-16 w-16'>0/60</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="relative flex flex-col min-w-0 mb-1 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border drop-shadow-lg">
                                                    <div className="flex-auto p-4">
                                                        <div className="flex flex-wrap -mx-3">
                                                            <div className="flex-none w-full max-w-full px-3">
                                                                <div>
                                                                    <h5>Buttons</h5>
                                                                    <h6 className="text-slate-500">Please choose buttons to be added to the
                                                                        template. You can choose upto 10 buttons.</h6>
                                                                    <TemplateDropdown elements={elements} setElements={setElements} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-wrap mt-3">
                                                            <div className="flex-none w-3/3 max-w-full">
                                                                <div>
                                                                    <h5>Business Account</h5>
                                                                    <h6 className="text-slate-500">Select the suitable business account for this template.</h6>
                                                                </div>
                                                            </div>
                                                            <BusinessProfiles token={token} setphoneNumberDetails={setphoneNumberDetails} phoneNumberDetails={phoneNumberDetails} businessId={formData.businessId} handleInputChange={handleInputChange} />
                                                            <div className="flex-auto p-1">
                                                                <button type="submit" className="inline-block px-10 py-3 mr-3 w-4/12 font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-gradient-to-tl from-purple-700 to-pink-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs">
                                                                    CREATE TEMPLATE
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="relative flex flex-col min-w-0 mb-1 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border drop-shadow-lg">

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="w-full max-w-full px-3 mb-6 lg:w-4/12 sm:flex-none xl:mb-0">
                                        <div
                                            className="relative flex flex-col center min-w-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border mb-4">
                                        </div>
                                        <div className="p-6 pb-0 mb-0 border-b-0 border-solid border-black-125 rounded-t-2xl">
                                            <div
                                                className="relative z-20 flex flex-col min-w-0 break-words bg-white border-0 border-solid dark:bg-gray-950 border-black-125 shadow-soft-xl dark:shadow-soft-dark-xl rounded-2xl bg-clip-border mb-4">
                                                <div>
                                                    <div className="w-full h-32" style={{ backgroundColor: "#449388" }}></div>

                                                    <div className="container mx-auto" style={{ marginTop: "-128px" }}>
                                                        <div className="py-6 h-96">
                                                            <div className="flex border border-grey rounded shadow-lg h-full">
                                                                <div className="flex-1 overflow-auto" style={{ backgroundColor: "#DAD3CC" }}>
                                                                    <div className="py-2 px-3">
                                                                        <div className="flex justify-center mb-4">
                                                                            <div className="rounded py-2 px-4"
                                                                                style={{ backgroundColor: "#FCF4CB" }}>
                                                                                <p className="text-xs">
                                                                                    Messages to this chat and calls are now secured with
                                                                                    end-to-end encryption. Tap for more info.
                                                                                </p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="mb-2 w-full ">
                                                                            <div className="rounded py-2 px-2"
                                                                                style={{ backgroundColor: "#E2F7CB" }}>
                                                                                <div className="flex items-center justify-center mb-2">
                                                                                    {
                                                                                        radioButton === 1 && (
                                                                                            selectedFile !== null ?
                                                                                                <img src={fileDataUrl} className="block w-full max-h-40" alt='upload file' /> :
                                                                                                <i className="fa fa-picture-o fa-5x block w-full text-center text-blue-400" aria-hidden="true"></i>
                                                                                        )}
                                                                                    {radioButton === 2 && (
                                                                                        <i className="fa fa-file-video-o fa-5x block w-full text-center text-blue-400" aria-hidden="true"></i>
                                                                                    )}
                                                                                    {radioButton === 3 && (
                                                                                        <i className="fa fa-file-text-o fa-5x block w-full text-center text-blue-400" aria-hidden="true"></i>
                                                                                    )}

                                                                                </div>
                                                                                {radioButton === 0 && (
                                                                                    <p className="text-sm mb-2 text-teal">
                                                                                        <b>{header.replace("{{1}}", headerValue)} </b>
                                                                                    </p>
                                                                                )}

                                                                                <p className="text-sm mb-2 mt-1">
                                                                                    {message}
                                                                                </p>
                                                                                <p className="italic text-xs mb-1 mt-1">
                                                                                    {footer}
                                                                                </p>
                                                                                <div className="divide-y divide-blue-200">
                                                                                    <div>
                                                                                        <p className="text-right mb-1 text-xs text-grey-dark mt-1">
                                                                                            12:45 pm
                                                                                        </p>
                                                                                    </div>
                                                                                    {elements.map((element) => (
                                                                                        ((element.type === 'URL') && (
                                                                                            <div className="flex items-center mb-2 justify-center">
                                                                                                <i className="fa fa-external-link text-blue-400" aria-hidden="true"></i>
                                                                                                <span className="ml-2 text-blue-400"><b>{element.text}</b></span>
                                                                                            </div>
                                                                                        )) ||
                                                                                        ((element.type === 'PHONE_NUMBER') && (
                                                                                            <div className="flex items-center mb-2 justify-center">
                                                                                                <i className="fa fa-phone text-blue-400" aria-hidden="true"></i>
                                                                                                <span className="ml-2 text-blue-400"><b>{element.text}</b></span>
                                                                                            </div>
                                                                                        )) ||
                                                                                        ((element.type === 'QUICK_REPLY') && (
                                                                                            elements.filter((element) => element.type === "QUICK_REPLY").length <= 2 &&
                                                                                            <div className="flex items-center mb-2 justify-center">
                                                                                                <i className="fa fa-share fa-flip-horizontal text-blue-400" aria-hidden="true"></i>
                                                                                                <span className="ml-2 text-blue-400"><b>{element.value}</b></span>
                                                                                            </div>
                                                                                        ))

                                                                                    ))}
                                                                                    {elements.filter((element) => element.type === "QUICK_REPLY").length === 3 && (
                                                                                        <div className="flex items-center my-1 justify-center">
                                                                                            <i className="fa fa-list text-blue-400" aria-hidden="true" ></i>
                                                                                            <span className="ml-2 text-blue-400"><b>See all options</b></span>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div >
    );
};


export default WhatsappTemplate;
