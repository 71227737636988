import { useEffect, useState } from 'react';

const usePayPalScript = (clientID) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (!clientID) {
            console.error("PayPal Client ID is required.");
            return;
        }

        const scriptId = 'paypal-script';

        if (document.getElementById(scriptId)) {
            setIsLoaded(true);
            return;
        }

        const script = document.createElement('script');
        script.id = scriptId;
        script.src = `https://www.paypal.com/sdk/js?client-id=${clientID}&currency=USD`;
        script.onload = () => setIsLoaded(true);
        document.body.appendChild(script);

        return () => {
            // Remove script when component unmounts to prevent duplicates
            document.body.removeChild(script);
        };
    }, [clientID]);

    return isLoaded;
};


export default usePayPalScript;
