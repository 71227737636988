import React, { useEffect, useState } from "react";
import 'choices.js/public/assets/styles/choices.css';
import { Pagination } from "../Pagination";
import * as XLSX from 'xlsx';
import { AleartMessage } from "../Aleart";
import '../../css/other.css'
import {DropDown, DropDownNoSearch} from "../DropDown";


const PhoneCallHisListTable = ({ currentPage, setCurrentPage, dataList = [], loading, itemsPerPage = 23 }) => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dataList.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(dataList.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    return (
        <div>
            <div>
                <table className="items-center justify-center w-full mb-0 align-top border-gray-200 text-slate-500">
                    <thead className={`${loading && "blur-sm"} align-bottom`}>
                        <tr>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-red-400 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">ID</th>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-red-400 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">Caller</th>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-red-400 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">Date</th>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-red-400 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">Start_Time</th>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-red-400 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">End_Time</th>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-red-400 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">From</th>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-red-400 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">To</th>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-red-400 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">Status</th>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-red-400 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">Duration</th>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-red-400 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">Price</th>

                        </tr>
                    </thead>
                    <tbody>
                        {loading && (
                            <div className="mt-72 animation" id="container">
                                <svg viewBox="0 0 100 100">
                                    <defs>
                                        <filter id="shadow">
                                            <feDropShadow dx="0" dy="0" stdDeviation="1.5" floodColor="#fc6767" />
                                        </filter>
                                    </defs>
                                    <circle
                                        id="spinner"
                                        style={{
                                            fill: 'transparent',
                                            stroke: '#dd2476',
                                            strokeWidth: '4px',
                                            strokeLinecap: 'round',
                                            filter: 'url(#shadow)',
                                        }} cx="50" cy="50" r="45" />
                                </svg>
                            </div>
                        )}
                        {currentItems.map((item, index) => (
                            <tr className={`${loading ? "blur-sm" : "font-semibold"}`} key={index}>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex px-3">
                                        <div className="my-auto">
                                            <h6 className="mb-0 leading-normal text-sm">{index + 1}</h6>
                                        </div>
                                    </div>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex px-3">
                                        <div className="my-auto">
                                            <h6 className="mb-0 leading-normal text-sm">{item.userName}</h6>
                                        </div>
                                    </div>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex px-3">
                                        <div className="my-auto">
                                            <h6 className="mb-0 leading-normal text-sm">{item.date.substring(0, 10)}</h6>
                                        </div>
                                    </div>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex px-3">
                                        <div className="my-auto">
                                            <h6 className="mb-0 leading-normal text-sm">{item.startTime}</h6>
                                        </div>
                                    </div>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex px-3">
                                        <div className="my-auto">
                                            <h6 className="mb-0 leading-normal text-sm">{item.endTime}</h6>
                                        </div>
                                    </div>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex px-3">
                                        <div className="my-auto">
                                            <h6 className="mb-0 leading-normal text-sm">{item.from}</h6>
                                        </div>
                                    </div>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex px-3">
                                        <div className="my-auto">
                                            <h6 className="mb-0 leading-normal text-sm">{item.to}</h6>
                                        </div>
                                    </div>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex px-3">
                                        <div className="my-auto">
                                            <h6 className="mb-0 leading-normal text-sm">{item.status}</h6>
                                        </div>
                                    </div>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex px-3">
                                        <div className="my-auto">
                                            <h6 className="mb-0 leading-normal text-sm">{item.duration}</h6>
                                        </div>
                                    </div>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex px-3">
                                        <div className="my-auto">
                                            <h6 className="mb-0 leading-normal text-sm">{item.price}</h6>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className={`${loading && "blur-sm"} py-2 flex justify-end`}>
                    <nav className="absolute left-0 ml-12 mt-2 block">
                        <ul className=" flex font-bold pl-0 rounded list-none flex-wrap">
                            Total Records: {dataList.length}
                        </ul>
                    </nav>
                    <nav className="block">
                        <ul className="flex pl-0 rounded list-none flex-wrap">
                            <li>
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-pink-500 bg-white text-pink-500"
                                >
                                    <i className="fas fa-chevron-left -ml-px" aria-hidden="true"></i>
                                </button>
                            </li>
                            {Pagination(totalPages, currentPage, handlePageChange)}
                            <li>
                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                    className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-pink-500 bg-white text-pink-500"
                                >
                                    <i className="fas fa-chevron-right -mr-px" aria-hidden="true"></i>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};



const CallRecordingTable = ({ currentPage, setCurrentPage, dataList = [], loading, itemsPerPage = 23, downloadRecodings, buttonDisabled}) => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dataList.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(dataList.length / itemsPerPage);
    const [user, setUser] = useState();

    const handleOptionClickUser = option => {
        setUser(option);
    };
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    return (
        <div>
            <div>
                <table className="items-center justify-center w-full mb-0 align-top border-gray-200 text-slate-500">
                    <thead className={`${loading && "blur-sm"} align-bottom`}>
                        <tr>
                            <th className="px-1 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-red-400 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400"></th>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-red-400 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">Dates</th>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-red-400 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading && (
                            <div className="mt-72 animation" id="container">
                                <svg viewBox="0 0 100 100">
                                    <defs>
                                        <filter id="shadow">
                                            <feDropShadow dx="0" dy="0" stdDeviation="1.5" floodColor="#fc6767" />
                                        </filter>
                                    </defs>
                                    <circle
                                        id="spinner"
                                        style={{
                                            fill: 'transparent',
                                            stroke: '#dd2476',
                                            strokeWidth: '4px',
                                            strokeLinecap: 'round',
                                            filter: 'url(#shadow)',
                                        }} cx="50" cy="50" r="45" />
                                </svg>
                            </div>
                        )}
                        {currentItems.map((item, index) => (
                            <tr className={`${loading ? "blur-sm" : "font-semibold"}`} key={index}>
                                <td className="p-1 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    {/* <div className="flex items-center px-3">
                                        <div className="flex items-center h-5">
                                            <input id={`checkbox-${index}`} aria-describedby={`checkbox-${index}`} name={`checkbox-${index}`} type="checkbox" className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500" />
                                        </div>
                                    </div> */}
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex px-3">
                                        <div className="my-auto">
                                            <h6 className="mb-0 leading-normal text-sm">{`Recorded Date: ${item}`}</h6>
                                        </div>
                                    </div>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex px-3">
                                        <div className="my-auto">
                                            <span onClick={()=> downloadRecodings(item)} className={`cursor-pointer py-2.2 px-3.6 text-xs rounded-1.8 inline-block whitespace-nowrap text-center ${buttonDisabled ? "bg-red-200 text-white-600" : "bg-lime-200 text-lime-600"} align-baseline font-bold uppercase leading-none`}>{buttonDisabled ? "Downloading In-Progress..." : "Download Recordings"}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className={`${loading && "blur-sm"} py-2 flex justify-end`}>
                    <nav className="absolute left-0 ml-12 mt-2 block">
                        <ul className=" flex font-bold pl-0 rounded list-none flex-wrap">
                            Total Records: {dataList.length}
                        </ul>
                    </nav>
                    <nav className="block">
                        <ul className="flex pl-0 rounded list-none flex-wrap">
                            <li>
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-pink-500 bg-white text-pink-500"
                                >
                                    <i className="fas fa-chevron-left -ml-px" aria-hidden="true"></i>
                                </button>
                            </li>
                            {Pagination(totalPages, currentPage, handlePageChange)}
                            <li>
                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                    className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-pink-500 bg-white text-pink-500"
                                >
                                    <i className="fas fa-chevron-right -mr-px" aria-hidden="true"></i>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};
export { PhoneCallHisListTable, CallRecordingTable };