// utils/authUtils.js

import axios from 'axios';
import { API_BASE_URL } from '../component/constants';
import { AleartMessage } from '../component/Aleart';


export const validateToken = async (navigate, token) => {
  if (!token || token === '') {
    navigate('/');
    return;
  }

  await axios.get(`${API_BASE_URL}/api/v1/validate/checkToken`, {
    headers: {
      'Authorization': `Bearer ${token}`
    },
  }).then(response => {
    if (response.status !== 202) {
      navigate('/');
      return;
    }
  }).catch(error => {
    console.error('Error:', error);
    navigate('/');
    return;
  });
};


export const validateTokenGeneric = async (navigate, token, role, endpoint) => {
  const redirectToHome = () => navigate('/');
  const isValidUserData = (data, role) => data && data.access && Array.isArray(data.access) && data.access.includes(role);

  if (!token) {
    redirectToHome();
    return;
  }

  try {
    const response = await axios.get(`${API_BASE_URL}${endpoint}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    });

    if (response.status !== 202 || !isValidUserData(response.data.body, role)) {
      navigate('/dashboard');
      AleartMessage({ icon: 'warning', title: 'No Access', text: 'Please Request Access' });
      return;
    }

    return response.data.body;
  } catch (error) {
    console.error('Error:', error);
    redirectToHome();
    return;
  }
};


export const validateTokenNew = (navigate, token, role) => validateTokenGeneric(navigate, token, role, '/api/v1/validate/checkToken');

export const validateTokenPay = (navigate, token, role) => validateTokenGeneric(navigate, token, role, '/api/v1/validate/checkTokenPhone?type=0');

export const validateTokenUser = (navigate, token, role) => validateTokenGeneric(navigate, token, role, '/api/v1/validate/checkTokenPhone?type=1');

export const validateTokenRecording = (navigate, token, role) => validateTokenGeneric(navigate, token, role, '/api/v1/validate/checkTokenPhone?type=2');


export const loginEvent = async (email, password, showLoading) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/v1/auth/authenticate`, {
      email: email,
      password: password,
    });
    
    const token = response.data.body;
    localStorage.setItem('token', token);
    if(token !== null || token !== ""){
      window.location.href = '/dashboard';
      return
    }
    showLoading(false)
  } catch (error) {
    return null;
  }
};



export const forgotPassword = async (email) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/auth/forgot-password?username=${email}`);
    return response.data.body;
  } catch (error) {
    return false;
  }
};


export const checkOTP = async (otp) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/auth/otp?OTP=${otp}`);
    return response.data.body;
  } catch (error) {
    return false;
  }
};


export const addNewUser = async (token, request, isEdited) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/v1/validate/register?edit=${isEdited}`, request, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    });
    return response.status;
} catch (error) {
    console.error('Error sending POST request:', error);
}
}


export const changePassword = async (password, email, otp) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/auth/reset-password?password=${password}&email=${email}&otp=${otp}`);
    return response.data.body;
  } catch (error) {
    return false;
  }
};