import React, { useEffect } from 'react';
import { useNavigate   } from 'react-router-dom';
import {validateToken} from './auth/authentication';
import { signUpNew } from './3rdParty/whatsapp';

const FacebookLoginButton = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    validateToken(navigate, token);
    // Load Facebook SDK asynchronously
    const loadFacebookSDK = () => {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: '2868096993332052',
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v17.0'
        });
      };

      (function(d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        const js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');

      // Ensure that the SDK is initialized
      if (window.FB) {
        window.fbAsyncInit();
      }
    };

    loadFacebookSDK();

    
    // Add sessionInfoListener code here
    const sessionInfoListener = (event) => {
      if (event.origin !== 'https://www.facebook.com') return;
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          // if user finishes the Embedded Signup flow
          if (data.event === 'FINISH') {
            const { phone_number_id, waba_id } = data.data;
            console.log('User finished signup:', phone_number_id, waba_id);
            signUpNew(phone_number_id, waba_id, token);
            // Perform actions when user finishes signup flow
          }
          // if user cancels the Embedded Signup flow
          else {
            const { current_step } = data.data;
            // Perform actions when user cancels signup flow
            console.log('User cancelled signup:', current_step);
          }
        }
      } catch {
        // Don’t parse info that’s not a JSON
        console.log('Non JSON Response', event.data);
      }
    };

    window.addEventListener('message', sessionInfoListener);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('message', sessionInfoListener);
    };

  }, [navigate]);

  const launchWhatsAppSignup = () => {
    // Launch Facebook login
    window.FB.login(
      function(response) {
        if (response.authResponse) {
           const accessToken = response.authResponse.accessToken;
           console.log(accessToken);
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      {
        config_id: '286493397281569', // configuration ID obtained in the previous step goes here
        response_type: 'code', // must be set to 'code' for System User access token
        override_default_response_type: true,
        extras: {
          setup: {
            "sessionInfoVersion": 2,
            display: 'popup'
          }
        }
      }
    );
  };
  

  return (
    <button
      onClick={launchWhatsAppSignup}
      style={{
        backgroundColor: '#1877f2',
        border: 0,
        borderRadius: '4px',
        color: '#fff',
        cursor: 'pointer',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontSize: '16px',
        fontWeight: 'bold',
        height: '40px',
        padding: '0 24px'
      }}
    >
      Login with Facebook
    </button>
  );
};

export default FacebookLoginButton;
