import React, { useEffect, useRef } from 'react';
import usePayPalScript from './usePayPalScript'; // Adjust the import path as needed

const PaymentButton = ({ clientID, amount, currency, onSuccess, onError}) => {
    const paypalButtonContainerRef = useRef(null);
    const isPayPalScriptLoaded = usePayPalScript(clientID);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (isPayPalScriptLoaded && window.paypal && !paypalButtonContainerRef.current.hasChildNodes()) {
                try {
                    window.paypal.Buttons({
                        createOrder: (data, actions) => actions.order.create({
                            purchase_units: [{
                                amount: {
                                    value: amount.toString(),
                                    currency_code: currency, // Ensure this matches your currency
                                },
                            }],
                            application_context: {
                                shipping_preference: 'NO_SHIPPING'
                            }
                        }),
                        onApprove: (data, actions) => actions.order.capture().then(details => {
                            onSuccess(details);
                        }),
                        onError: err => {
                            onError(err);
                        },
                        style: {
                            layout: 'vertical', // or 'horizontal'
                            color: 'gold', // or 'blue', 'silver', 'black', 'white'
                            shape: 'rect', // or 'pill'
                            label: 'paypal', // or 'checkout', 'buynow', 'pay'
                            // Specify other style properties as needed
                            height: 40, // Button height, in pixels
                            // You can also specify 'tagline: false' to hide the tagline.
                        }

                    }).render(paypalButtonContainerRef.current);
                } catch (error) {
                    console.error("Failed to render PayPal Buttons:", error);
                }
            }
        }, 500); // Delay for ensuring SDK is fully loaded

        return () => clearTimeout(timeoutId);
    }, [clientID, amount, currency, onSuccess, onError, isPayPalScriptLoaded]);

    return <div ref={paypalButtonContainerRef}></div>;
};

export default PaymentButton;
