import axios from "axios";
import { API_BASE_URL } from "../component/constants";

export const GetAllProjects = async (token) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/v1/project`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data.body;

    } catch (error) {
        console.error('Error sending POST request:', error);
    }
};


export const saveProspects = async (token, processedData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/v1/project`, processedData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        return response.data.body;
    } catch (error) {
        console.error('Error sending POST request:', error);
    }
};