import React, { useState } from "react";
import 'choices.js/public/assets/styles/choices.css';
import { Pagination } from "./Pagination";

const DashboardTable = ({ dataList, itemsPerPage = 11, isModalVisible }) => {
    const [currentPage, setCurrentPage] = useState(1);

    if (!Array.isArray(dataList)) {
        console.error('dataList must be an array.');
        return null;
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dataList.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(dataList.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    let percentage = 0;
    const handlePersentage = (item) => {
        percentage = Math.round((item.sent / item.quantity) * 100)
    }
    
    return (
        <div>
            <div style={isModalVisible ? { zIndex: 900, position: 'relative' } : {}} className={isModalVisible ? "blur-lg" : ""}>
                <table className="items-center justify-center w-full mb-0 align-top border-gray-200 text-slate-500">
                    <thead className="align-bottom">
                        <tr>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Project</th>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">User</th>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Start-Date</th>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">End-Date</th>
                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Status</th>
                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Completion</th>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Numbers</th>
                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((item, index) => (
                            <tr key={index}>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex px-3">
                                        <div className="my-auto">
                                            <h6 className="mb-0 leading-normal text-sm">{item.name}</h6>
                                        </div>
                                    </div>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <p className="mb-0 font-semibold leading-normal text-sm">{item.user}</p>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <p className="mb-0 font-semibold leading-normal text-sm">{item.startDate && item.startDate.split("T")[0]}</p>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <p className="mb-0 font-semibold leading-normal text-sm"> {item.endDate && item.endDate.split("T")[0]}</p>
                                </td>
                                <td className="p-2 leading-normal text-center align-middle bg-transparent border-b text-sm whitespace-nowrap shadow-transparent">
                                    {(item.status === "PENDING") && (
                                        <span className="bg-gradient-to-tl from-red-600 to-yellow-400 px-2 text-xxs rounded py-1 inline-block whitespace-nowrap text-center align-baseline font-bold uppercase leading-none text-white">PENDING</span>
                                    )}
                                    {(item.status === "IN_PROGRESS") && (
                                        <span className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 px-2 text-xxs rounded py-1 inline-block whitespace-nowrap text-center align-baseline font-bold uppercase leading-none text-white">IN-PROGRESS</span>
                                    )}
                                    {(item.status === "COMPLETED") && (
                                        <span className="bg-gradient-to-tl from-green-600 to-lime-400 px-2 text-xxs rounded py-1 inline-block whitespace-nowrap text-center align-baseline font-bold uppercase leading-none text-white">COMPLETED</span>
                                    )}
                                </td>
                                {handlePersentage(item)}
                                <td className="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <div className="flex items-center justify-center">
                                        <span className="mr-2 font-semibold leading-tight text-xs">{percentage}%</span>
                                        <div>
                                            <div className="text-xs h-0.75 w-30 m-0 flex overflow-visible rounded-lg bg-gray-200">
                                                {(percentage > 0 && percentage <= 20) && (
                                                    <div className="duration-600 ease-soft bg-gradient-to-tl from-red-600 to-red-400 -mt-0.38 -ml-px flex h-1.5 w-1/5 flex-col justify-center overflow-hidden whitespace-nowrap rounded bg-fuchsia-500 text-center text-white transition-all" role="progressbar" aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100"></div>
                                                )}
                                                {(percentage > 20 && percentage <= 40) && (
                                                    <div className="duration-600 ease-soft bg-gradient-to-tl from-red-600 to-yellow-400 -mt-0.38 -ml-px flex h-1.5 w-2/5 flex-col justify-center overflow-hidden whitespace-nowrap rounded bg-fuchsia-500 text-center text-white transition-all" role="progressbar" aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100"></div>
                                                )}
                                                {(percentage > 40 && percentage <= 60) && (
                                                    <div className="duration-600 ease-soft bg-gradient-to-tl from-red-600 to-pink-400 -mt-0.38 -ml-px flex h-1.5 w-3/5 flex-col justify-center overflow-hidden whitespace-nowrap rounded bg-fuchsia-500 text-center text-white transition-all" role="progressbar" aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100"></div>
                                                )}
                                                {(percentage > 60 && percentage <= 80) && (
                                                    <div className="duration-600 ease-soft bg-gradient-to-tl from-orange-600 to-yellow-400 -mt-0.38 -ml-px flex h-1.5 w-4/5 flex-col justify-center overflow-hidden whitespace-nowrap rounded bg-fuchsia-500 text-center text-white transition-all" role="progressbar" aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100"></div>
                                                )}
                                                {(percentage > 80 && percentage <= 100) && (
                                                    <div className="duration-600 ease-soft bg-gradient-to-tl from-green-600 to-yellow-400 -mt-0.38 -ml-px flex h-1.5 w-full flex-col justify-center overflow-hidden whitespace-nowrap rounded bg-fuchsia-500 text-center text-white transition-all" role="progressbar" aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100"></div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <p className="mb-0 font-semibold leading-normal text-sm">{item.number}</p>
                                </td>
                                <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                    <button value={item.id} type="button" className="inline-block px-6 py-3 mr-3 font-bold text-center align-middle transition-all rounded-lg cursor-pointer bg-fuchsia-500/0 leading-pro text-xs ease-soft-in tracking-tight-soft bg-150 bg-x-25 hover:bg-fuchsia-500/25 hover:scale-102 active:bg-fuchsia/45 text-fuchsia-500">Edit</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="py-2 flex justify-end">
                    <nav className="block">
                        <ul className="flex pl-0 rounded list-none flex-wrap">
                            <li>
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-pink-500 bg-white text-pink-500"
                                >
                                    <i className="fas fa-chevron-left -ml-px" aria-hidden="true"></i>
                                </button>
                            </li>
                            {Pagination(totalPages, currentPage, handlePageChange)}
                            <li>
                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                    className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-pink-500 bg-white text-pink-500"
                                >
                                    <i className="fas fa-chevron-right -mr-px" aria-hidden="true"></i>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export { DashboardTable };