import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import LoginPage from './LoginPage';
import Dashboard from './dashboard';
import Test from './test';
import MainPage from './Dashboard_Pages/whatsappManager';
import WhatsappTemplate from './Dashboard_Pages/whatsappTemplateManager';
import TemplateManager from './Dashboard_Pages/tamplateManager';
import CallApp from './PhoneApp/callApp';
import CallLogs from './PhoneApp/callLogs';
import PaymentResult from './component/PaymentComponent';
import Gateway from './Payment/Gateway';
import { Failed, Success } from './Payment/Response';
import AddUser from './PhoneApp/addUser';
import CallRecording from './PhoneApp/callRecording';

function App() {
  const token = localStorage.getItem('token');
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<LoginPage />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/test" element={<Test />} />
          <Route exact path='/whatsapp-manager' element={<MainPage />} />
          <Route exact path='/template' element={<TemplateManager />} />
          <Route exact path='/template-manager' element={<WhatsappTemplate />} />

          <Route exact path='/phone-manager' element={<CallApp />} />
          <Route exact path='/phone-manager/history' element={<CallLogs />} />
          <Route exact path="/phone-manager/add-user" element={<AddUser />} />
          <Route exact path="/phone-manager/call-recording" element={<CallRecording />} />
          <Route exact path="/phone-manager/payment-gateway" element={<Gateway />} />

          <Route exact path="/payment-result" element={<PaymentResult token={token} />} />
          <Route exact path="/phone-manager/payment-gateway/success" element={<Success />} />
          <Route exact path="/phone-manager/payment-gateway/failed" element={<Failed />} />
          
          
        </Routes>
      </div>
    </Router>
  );

}

export default App;
