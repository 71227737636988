import React from 'react';
import $ from "jquery";
import axios from 'axios';
import { API_BASE_URL } from '../component/constants';


export const PaymentUtils = async ({ token, totalAmount, amount }) => {

    const payload = await Paynow(token, totalAmount, amount);
    const userDeatils = payload.body;
    console.log(userDeatils);
    const payment = userDeatils.encrypted;
    const company = userDeatils.company;
    
    const data = `${company}|${amount}`;
    const custom_fields = btoa(data);

    $('.something').append(`
            <form action="https://webxpay.com/index.php?route=checkout/billing" name="vote" id="vote" method="post" style="display:none;">
                <input type="text" name="first_name" value=${userDeatils.firstName} />
                <input type="text" name="last_name" value=${userDeatils.lastName} />
                <input type="text" name="email" value="shaabd7@gmail.com" />
                <input type="text" name="contact_number" value=${userDeatils.number} />
                <input type="text" name="address_line_one" value=${userDeatils.address} />
                <input type="text" name="process_currency" value="USD" />
                <input type="text" name="secret_key" value="08cd75b6-b361-45ee-a4aa-da8e8d38920f" />
                <input type="text" name="custom_fields" value=${custom_fields} />
                <input type="text" name="payment" value="${payment}" />
            </form>
        `);
        
    document.getElementById("vote").submit();

    return (
        <div id="outer-container" className="container-fluid transiton-activatedg">
            <div id="inside-container" className="container">
                <div className="row row_clr">
                    <div className="something"></div>
                </div>
            </div>
        </div>
    )
}


const Paynow = async (token, totalAmount, amount) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/v1/payment/payNow?totalAmount=${totalAmount}&amount=${amount}`, null, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error sending POST request:', error);
    }
};



export const DownloadInvoices = async (token, invoiceId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/v1/payment/invoice?id=${invoiceId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            responseType: 'blob',
        });

        if (response.status === 200) {
            const file = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', 'invoice.pdf'); // Set the default filename for the download
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(fileURL); // Clean up the URL after triggering the download
            document.body.removeChild(link); // Remove the link from the DOM
        } else {
            console.error('Failed to download the invoice:', response.status);
        }

    } catch (error) {
        console.error('Error sending POST request:', error);
    }
};
