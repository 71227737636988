import React, { useState, useEffect } from "react";
import 'choices.js/public/assets/styles/choices.css';
import { Page1, Page2, Page3 } from "./CPPages";
import * as XLSX from 'xlsx';
import { saveProspects } from "../api/project";
import WebSocketService from './WebSocketService';

const CreateProject = ({ token, setIsModalVisible }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(true); // Initially checked
    const [selectedFile, setSelectedFile] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [progress, setProgress] = useState(0);
    const [totalCount, settotalCount] = useState(0);

    const [formData, setFormData] = useState({
        projectName: "",
        projectDescription: "",
        selectedBusinessAccount: "",
        selectedPhoneNumber: "",
    });


    useEffect(() => {
        const webSocketService = WebSocketService();
        webSocketService.connect();

        webSocketService.connect(
            () => {
                // Callback for successful connection
                webSocketService.subscribeToProgress((newProgress) => {
                    setProgress(newProgress);
                });
            },
            (error) => {
                // Callback for connection error
                console.error("WebSocket connection error:", error);
            }
        );
    }, []);

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: type === "textarea" || type === "text" ? value : e.target.value }));
    };



    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleCheck = () => {
        setIsChecked(!isChecked);
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (isUploadDisabled) {
            return;
        }

        const reader = new FileReader();
        try {
            reader.onload = (e) => {
                try {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                    const expectedColumns = ['Name', 'ISO', 'Number'];
                    const actualColumns = jsonData[0].slice(0, 3);
                    const allColumns = jsonData[0];

                    if (
                        JSON.stringify(expectedColumns) !== JSON.stringify(actualColumns)
                    ) {
                        throw new Error('Incorrect column order. Please ensure columns are in the order: Name, Country_Code, Number.');
                    }

                    const processedData = jsonData.map(row => {
                        const nameIndex = actualColumns.indexOf('Name');
                        const countryCodeIndex = actualColumns.indexOf('ISO');
                        const numberIndex = actualColumns.indexOf('Number');

                        const otherFields = {};
                        allColumns.forEach((column, index) => {
                            if (index !== nameIndex && index !== countryCodeIndex && index !== numberIndex) {
                                otherFields[column.replace(" ", "_")] = row[index];
                            }
                        });

                        const result = {
                            name: row[nameIndex],
                            iso: row[countryCodeIndex],
                            number: row[numberIndex],
                            other: JSON.stringify(otherFields)
                        };
                        return result;
                    });
                    const otherFieldsKeys = Object.keys(processedData);
                    if (otherFieldsKeys.length > 0) {
                        const propertyToRemove = otherFieldsKeys[0];
                        delete processedData[propertyToRemove];
                    }

                    const project = {
                        name: formData.projectName,
                        description: formData.projectDescription,
                        numberID: formData.selectedPhoneNumber,
                        prospectList: processedData
                    };

                    settotalCount(Object.keys(processedData).length);

                    saveProspects(token, project);
                } catch (error) {
                    console.error('Error reading the Excel file:', error);
                }
            };
            reader.readAsArrayBuffer(selectedFile);
        } catch (error) {
            console.error('Error reading the Excel file:', error);
        }
        closeModal();
    };



    const handlePageForwardClick = (num) => {
        setPageNumber(num);
    }

    const isUploadDisabled = !isChecked || !selectedFile;

    return (
        <div className="my-modal flex justify-center w-10/12 flex-wrap mx-3 mb-5 removable">
            <div className="w-2/3 max-w-full px-3 text-center flex-0">
                <h3 className="mt-12 font-semibold">Create New Project</h3>
                <h5 className="font-normal dark:text-white text-white-500 font-semibold">This information will let us know more about you.</h5>
                <div className="mb-12">
                    <div className="flex flex-wrap -mx-3">
                        <div className="w-full max-w-full px-3 mx-auto my-12 [flex:0_0_auto]">
                            <div className="grid grid-cols-3">
                                <p aria-controls="about" type="button" className="font-semibold before:w-[0.85rem] before:h-[0.85rem] before:rounded-[50%] before:scale-[1.2] rounded-0 indent-[-82.5rem] relative m-0 cursor-pointer border-none bg-transparent px-1.5 pb-0.5 pt-5 text-lime-400 outline-none transition-all ease-linear before:absolute before:top-0 before:left-1/2 before:z-30 before:box-border before:block before:-translate-x-1/2 before:border-2 before:border-solid before:border-current before:bg-current before:transition-all before:ease-linear before:content-[''] sm:indent-0" title="About"><span className="text-[#344767]">Project</span></p>
                                <p aria-controls="account" type="button" className={`font-semibold before:w-[0.85rem] before:h-[0.85rem] before:rounded-[50%] after:top-[5px] rounded-0 indent-[-82.5rem] relative m-0 cursor-pointer border-none bg-transparent px-1.5 pb-0.5 pt-5 outline-none transition-all ease-linear before:absolute before:top-0 before:left-1/2 before:z-30 before:box-border before:block before:-translate-x-1/2 before:border-2 before:border-solid before:border-current before:transition-all before:ease-linear before:content-[''] after:absolute after:left-[calc(-50%-13px/2)] after:z-10 after:block after:h-0.5 after:w-full after:bg-current after:transition-all after:ease-linear after:content-[''] sm:indent-0 ${pageNumber >= 2 ? "before:scale-[1.2] before:bg-current text-lime-400" : "before:bg-white text-[#dee2e6]"}`} title="Account"><span className="text-[#344767]">Buniness Account</span></p>
                                <p aria-controls="address" type="button" className={`font-semibold before:w-[0.85rem] before:h-[0.85rem] before:rounded-[50%] after:top-[5px] rounded-0 indent-[-82.5rem] relative m-0 cursor-pointer border-none bg-transparent px-1.5 pb-0.5 pt-5 outline-none transition-all ease-linear before:absolute before:top-0 before:left-1/2 before:z-30 before:box-border before:block before:-translate-x-1/2 before:border-2 before:border-solid before:border-current before:transition-all before:ease-linear before:content-[''] after:absolute after:left-[calc(-50%-13px/2)] after:z-10 after:block after:h-0.5 after:w-full after:bg-current after:transition-all after:ease-linear after:content-[''] sm:indent-0 ${pageNumber === 3 ? "before:scale-[1.2] before:bg-current text-lime-400" : "before:bg-white text-[#dee2e6]"}`} title="Address"><span className="text-[#344767]">Prospect</span></p>
                            </div>
                        </div>
                    </div>
                    <div className=" flex flex-wrap -mx-3">
                        <div className="w-full max-w-full px-3 m-auto [flex:0_0_auto] overflow-y-auto overflow-x-hidden min-h-[520px] dark:shadow-dark-xl shadow-xl drop-shadow-2xl">
                            <form className="relative mb-32">
                                {pageNumber === 1 && (
                                    <Page1
                                        setIsModalVisible={setIsModalVisible}
                                        formData={formData}
                                        handleInputChange={handleInputChange}
                                        handlePageForwardClick={handlePageForwardClick}
                                    />
                                )}
                                {pageNumber === 2 && (
                                    <Page2
                                        token={token}
                                        formData={formData}
                                        handleInputChange={handleInputChange}
                                        handlePageForwardClick={handlePageForwardClick}
                                    />
                                )}
                                {pageNumber === 3 && (
                                    <Page3
                                        setIsModalVisible={setIsModalVisible}
                                        totalCount={totalCount}
                                        handleUpload={handleUpload}
                                        isUploadDisabled={isUploadDisabled}
                                        handleCheck={handleCheck}
                                        isChecked={isChecked}
                                        progress={progress}
                                        closeModal={closeModal}
                                        isModalOpen={isModalOpen}
                                        openModal={openModal}
                                        handleFileChange={handleFileChange}
                                        handlePageForwardClick={handlePageForwardClick}
                                    />
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { CreateProject };