import React from 'react';
import "../styles.css";
import { hourglass } from 'ldrs'

const InfinityLoader = () => {
  hourglass.register();
  return (
    <div className="infinitycontainer">
      <div className="gradient-circle">
        <l-hourglass
          size="150"
          bg-opacity="0.5"
          speed="1.75"
          color="rgb(250, 177, 177)"
        ></l-hourglass>
      </div>
    </div>
  );
};

export default InfinityLoader;